import Sidebar from '.';
import Icons from 'components/Icons';
import { LogoBox } from './components/LogoBox';
import { FC } from 'react';
import { SidebarStyled } from './SidebarStyled';
import Menu from './components/Menu';
import { Button } from 'antd';
import { useAppDispatch } from 'hooks/redux';
import {
  getTokenFromCookies,
  removeTokenFromCookies,
} from 'utils/helpers/cookies';
import authSlice from 'store/slices/authSlice';
import { authApi } from 'store/api/authApi';
import { InputTestApi } from 'components/InputTestApi';
import { Item } from './components/Menu/components/Item';
import { MenuStyled } from './components/Menu/MenuStyled';
import { LogoutOutlined } from '@ant-design/icons';

interface ISidebarProps {}

const SidebarContainer: FC<ISidebarProps> = () => {
  const dispatch = useAppDispatch();
  const [logOut, { isLoading: isLoadingLogOut, error: errorLogOut }] =
    authApi.useLoginOutMutation();

  const signOutHandler = async () => {
    try {
      const token = getTokenFromCookies();
      // const data = await logOut(token).unwrap();
      dispatch(authSlice.actions.deleteCredentials());
    } catch {}
  };

  return (
    <SidebarStyled width={300} className="site-layout-background">
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          background: '#013365',
          padding: '15px',
          borderRadius: '0 15px 15px 0',
        }}
      >
        <LogoBox>
          <Icons.Logo />
          ГУП СК "РРЦ СК"
        </LogoBox>
        <Menu />
        <div style={{ flexGrow: 1 }}></div>
        <MenuStyled>
          <Item
            // style={{ masrgin: '10px 0' }}
            // icon={}
            isSelected={false}
            key={'sign-out'}
            icon={<LogoutOutlined />}
            onClick={() => signOutHandler()}
          >
            Выход
          </Item>
        </MenuStyled>
      </div>
    </SidebarStyled>
  );
};

export default SidebarContainer;
