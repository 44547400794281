import React, { FC } from 'react';
import { Tag, TagProps } from 'antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  LikeOutlined,
} from '@ant-design/icons';

interface IRegisterStatusProps extends TagProps {
  status: string;
}

const RegisterStatus: FC<IRegisterStatusProps> = ({ status, ...other }) => {
  const tagSettings = {
    icon: <ClockCircleOutlined />,
    color: 'warning',
  };

  if (status === 'Создан') {
    tagSettings.icon = <LikeOutlined />;
    tagSettings.color = 'processing';
  } else if (status === 'Отправлен') {
    tagSettings.icon = <CheckCircleOutlined />;
    tagSettings.color = 'success';
  } else if (status === 'Изменен') {
    tagSettings.icon = <ExclamationCircleOutlined />;
    tagSettings.color = 'error';
  }

  return (
    <Tag {...tagSettings} {...other}>
      {status}
    </Tag>
  );
};

export default RegisterStatus;
