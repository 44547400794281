import React, {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Divider,
  InputRef,
  List,
  message,
  Modal,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import Paper from '../../components/Paper';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';
import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  ExclamationOutlined,
} from '@ant-design/icons';
import downloadFile from '../../utils/helpers/downloadFile';
import { apiAddressConst } from '../../utils/apiAddressConst';
import { API } from '../../utils/endpointsConst';
import { dateSorter } from '../../utils/helpers/dataSort';
import moment from 'moment/moment';
import { stringSort } from '../../utils/helpers/stringSort';
import { userApi } from '../../store/api/userApi';
import { reportingApi } from '../../store/api/reportingApi';
import ReportRenderModal from './components/ReportRenderModal';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import {
  IReportStoryMapped,
  ReportFileState,
} from '../../store/models/IReportStory';
import { useSignalREventListener } from '../../hooks/signalr-hooks';
import { RenderRepInfo } from '../../store/models/ISignalR';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getFilterSearchSettings } from '../../utils/helpers/getFilterSearchSettings';
import getFilterCheckboxSettings from '../../utils/helpers/getFilterCheckboxSettings';
import { IPaymentRegisterMapped } from '../../store/models/IPaymentRegister';
import { DataNode } from 'antd/lib/tree';
import { IDebtRegisterMapped } from '../../store/models/IDebtRegister';
import { getFilterDatePickerSettings } from '../../utils/helpers/getFilterDataPickerSettings';
import OrganizationSelect from '../../components/OrganizationSelect';
import { organizationApi } from '../../store/api/organizationApi';
import { reportingActions } from '../../store/slices/reportingSlice';

interface IReportingProps {
  name: string;
}

const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  gap: 16px;
`;

const ReportListItem = styled(List.Item)`
  &&& {
    cursor: pointer;

    &:hover {
      background: #f8f8f8;
    }

    &:last-child {
      border-radius: 0 0 15px 15px;
    }
  }
`;

const Reporting: FC<IReportingProps> = ({ name }) => {
  const { data: userData, isLoading: userDataLoading } =
    userApi.useGetUserQuery();
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const [isCreatingTimePickerOpened, setIsCreatingTimePickerOpened] =
    useState(false);
  const [dataRangeFilter, setDataRangeFilter] = useState<React.Key[]>([]);

  const { selectedOrgId } = useAppSelector((state) => state.reporting);

  const {
    data: reportOptions,
    isFetching: reportOptionsLoading,
    error: reportOptionsError,
    refetch: refetchReportOptions,
  } = reportingApi.useGetOrgReportsQuery({
    ownerId: selectedOrgId || userData?.orgId,
  });
  useEffect(() => {
    refetchReportOptions();
  }, [userData]);

  const {
    data: reportStories,
    isFetching: reportStoriesLoading,
    error: reportStoriesError,
  } = reportingApi.useGetReportStoriesQuery();
  useErrorMessage(reportStoriesError);

  const [deleteReportStory, {}] = reportingApi.useDeleteReportStoryMutation();

  const { connection: signalRConnection } = useAppSelector(
    (state) => state.signalR
  );

  const setSelectedOrgId = (orgId: string) =>
    dispatch(reportingActions.setSelectedOrgId(orgId));

  useEffect(() => {
    if (userData && !selectedOrgId) {
      setSelectedOrgId(userData.orgId);
    }
  }, [userData]);

  useSignalREventListener<RenderRepInfo>(
    signalRConnection,
    'SendRenderRepInfo',
    (signal) => {
      console.log(signal);
      dispatch(
        reportingApi.util.updateQueryData(
          'getReportStories',
          undefined,
          (draftPosts) => {
            const indexOfStory = draftPosts.findIndex(
              (elem) => elem.id === signal.reportId
            );
            if (indexOfStory !== -1) {
              draftPosts[indexOfStory] = {
                ...draftPosts[indexOfStory],
                state: signal.state,
                fileId: signal.fileId,
              };
              if (signal.state === ReportFileState.Done) {
                message.success(
                  `Файл "${draftPosts[indexOfStory].fileName}" сформирован!`
                );
              } else if (signal.state === ReportFileState.Error) {
                message.error(
                  `Ошибка формирования "${draftPosts[indexOfStory].fileName}"!`
                );
              }
            }
          }
        )
      );
    }
  );

  const formatFilterItems = useMemo(() => {
    const mappedData = reportStories?.map(
      (record) =>
        ({
          title: record.format,
          key: record.format,
        } as DataNode)
    );
    return mappedData?.filter(
      (value, index) =>
        mappedData.findIndex((obj) => obj.key === value.key) === index
    );
  }, [reportStories]);

  const deleteRecord = (id: string) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Вы уверены, что хотите удалить этот файл?',
      // content: (
      //   <Typography.Text type="secondary" style={{ marginTop: '30px' }}>
      //     Настройки организаций удалены не будут.
      //   </Typography.Text>
      // ),
      onOk: () => {
        deleteReportStory({ id });
      },
      onCancel: () => {},
    });
  };

  const columns: ColumnsType<IReportStoryMapped> = [
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip title={'Скачать'}>
            <Button
              disabled={!record.fileId && record.state !== ReportFileState.Done}
              loading={record.state === ReportFileState.Loading}
              type="default"
              shape="circle"
              size="small"
              icon={
                record.state === ReportFileState.Done ? (
                  <DownloadOutlined />
                ) : (
                  <ExclamationOutlined />
                )
              }
              onClick={() =>
                downloadFile(
                  `${apiAddressConst}${API.files.download}?id=${record.fileId}`,
                  `${record.fileName}.${record.format}`
                )
              }
            />
          </Tooltip>
          <Tooltip title={'Удалить'}>
            <Button
              size="small"
              type="default"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteRecord(record.id)}
              danger
            />
          </Tooltip>
        </div>
      ),
      width: '1%',
      className: 'ant-table-row-actions-cell',
      fixed: 'left',
    },
    {
      title: 'Название',
      dataIndex: 'fileName',
      key: 'fileName',
      // width: '100%',
      sorter: (a, b) => stringSort(a.fileName, b.fileName, 'ascend'),
      ...getFilterSearchSettings<IReportStoryMapped>('fileName', {
        searchInput: searchInput as MutableRefObject<InputRef>,
        searchText,
        searchedColumn,
        setSearchedColumn,
        setSearchText,
      }),
    },
    {
      title: 'Формат',
      dataIndex: 'format',
      key: 'format',
      width: '110px',
      sorter: (a, b) => stringSort(a.format, b.format, 'ascend'),
      ...getFilterCheckboxSettings<IReportStoryMapped>('format', {
        mode: 'menu',
        filters: formatFilterItems,
      }),
    },
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: '170px',
      // render: (_, record) =>
      //   moment(record.date).format('DD.MM.YYYY, HH:mm:ss').toString(),
      sorter: (a, b) =>
        dateSorter(
          moment(a.date, 'DD.MM.YYYY, HH:mm:ss'),
          moment(b.date, 'DD.MM.YYYY, HH:mm:ss')
        ),
      ...getFilterDatePickerSettings<IReportStoryMapped>('date', {
        isDatePickerOpened: isCreatingTimePickerOpened,
        setIsDatePickerOpened: setIsCreatingTimePickerOpened,
        range: true,
        showTime: true,
        state: {
          get: dataRangeFilter,
          set: setDataRangeFilter,
        },
      }),
    },
  ];

  return (
    <Wrap>
      <Paper
        type="stroke"
        header={<Typography.Title level={5}>История</Typography.Title>}
        css={`
          //flex-grow: 1;
          width: calc(100% - 400px - 16px);
        `}
      >
        {/*<Empty description={'Пока находится в разработке...'}></Empty>*/}
        {/*<Stimulsoft style={{ flexGrow: 1 }} />*/}
        {/*<SettingPanel />*/}
        <Table
          pagination={{
            showSizeChanger: true,
          }}
          size="small"
          columns={columns}
          bordered
          dataSource={reportStories}
          loading={reportStoriesLoading}
          sticky
          scroll={{ x: 'max-content', y: 'auto' }}
          css={`
            & .ant-pagination {
              margin-bottom: 0;
            }
          `}
          // style={{ flexGrow: 1 }}
        />
      </Paper>
      <Paper
        type="stroke"
        header={
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography.Title level={5} style={{ width: 100 }}>
              Отчеты
            </Typography.Title>
          </div>
        }
        css={''}
      >
        {userData?.orgId === '-1' && (
          <>
            <OrganizationSelect
              onChange={(value) => setSelectedOrgId(value || userData?.orgId)}
              value={selectedOrgId || userData?.orgId}
              placeholder="Организация"
              style={{ marginBottom: '16px', width: '100%' }}
            />
            <Divider
              style={{ margin: '0px -24px', width: 'calc(100% + 48px)' }}
            />
          </>
        )}
        <List
          dataSource={reportOptions}
          loading={reportOptionsLoading || userDataLoading}
          renderItem={(item) => (
            <ReportRenderModal
              button={({ showModal }) => (
                <ReportListItem
                  style={{ padding: '8px 24px' }}
                  onClick={showModal}
                >
                  {item.name}
                </ReportListItem>
              )}
              selectedReportId={item.id}
              selectedReportName={item.name}
            />
          )}
          style={{
            width: '400px',
            margin:
              userData?.orgId === '-1'
                ? '0px -24px -16px -24px'
                : '-16px -24px',
          }}
          size={'small'}
        />
      </Paper>
    </Wrap>
  );
};

export default Reporting;
