import withIconWrap from '../HOC/withIconWrap';
import { ReactComponent as IconLogo } from '../assets/Icons/logo.svg';
import { ReactComponent as IconPaymentRegister } from '../assets/Icons/payment-registers.svg';
import { ReactComponent as IconDebtRegister } from '../assets/Icons/debt-registers.svg';
import { ReactComponent as IconSettings } from '../assets/Icons/settings.svg';
import { ReactComponent as IconReporting } from '../assets/Icons/reporting.svg';
import {
  TeamOutlined,
  FileTextOutlined,
  ApartmentOutlined,
  SettingOutlined,
} from '@ant-design/icons';

export default {
  Logo: withIconWrap(IconLogo, { width: '40px' }),
  IconPaymentRegister: withIconWrap(IconPaymentRegister, { width: '20px' }),
  IconDebtRegister: withIconWrap(IconDebtRegister, { width: '20px' }),
  IconSettings: withIconWrap(SettingOutlined),
  IconReporting: withIconWrap(IconReporting, { width: '20px' }),
  IconUsers: withIconWrap(TeamOutlined),
  IconDoc: withIconWrap(FileTextOutlined),
  IconOrg: withIconWrap(ApartmentOutlined),
};
