import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Space } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { MutableRefObject } from 'react';

// type HandleSearchType = <T,>(
//   selectedKeys: string[],
//   confirm: (param?: FilterConfirmProps) => void,
//   dataIndex: keyof T,
// ) => void;

interface IGetFilterSearchSettingsPropsConfig {
  searchInput: MutableRefObject<InputRef>;
  searchedColumn: any;
  setSearchedColumn: (searchedColumn: any) => any;
  searchText: any;
  setSearchText: (searchText: any) => any;
}

export const getFilterSearchSettings = <T,>(
  dataIndex: keyof T,
  config: IGetFilterSearchSettingsPropsConfig
): ColumnType<T> => {
  const {
    searchInput,
    setSearchedColumn,
    searchedColumn,
    searchText,
    setSearchText,
  } = config;
  type DataIndex = keyof T;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => (
      <div style={{ padding: 8, width: 296 - 8 }}>
        <Input
          ref={searchInput}
          placeholder={'Найти значение'}
          value={selectedKeys[0] as string}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          // onPressEnter={() =>
          //   handleSearch(selectedKeys as string[], confirm, dataIndex)
          // }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            type="link"
            disabled={!selectedKeys[0]}
          >
            Сбросить
          </Button>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      String(record[dataIndex])
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.current?.select(), 100);
      }
    },
    // render: text =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  };
};
