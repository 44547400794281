import { Menu } from 'antd';
import styled from 'styled-components';

export const MenuStyled = styled(Menu)`
  background: none !important;
  border: none !important;
  margin-top: 25px !important;
  .ant-menu-item-selected {
    background: none !important;
  }
  .ant-menu-item-active {
    background: none !important;
  }
`;
