import { Key } from 'react';

export enum ReportFileState {
  Loading = 'Loading',
  Done = 'Done',
  Error = 'Error',
}

export interface IReportStory {
  id: string;
  userId: string;
  fileName: string;
  date: string;
  format: string;
  fileId: string;
  state: ReportFileState;
}

export interface IReportStoryMapped extends IReportStory {
  key: Key;
}
