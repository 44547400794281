import { useAppSelector } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';
import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface IRequireUnauth {
  children?: React.ReactNode;
}

const RequireUnauth: FC<IRequireUnauth> = ({ children }) => {
  const auth = useAuth();
  const StoreRoles = useAppSelector((state) => state.auth.token.Roles) || [];

  if (StoreRoles.length > 0) {
    // Пользователь авторизоан, нужно перенаправлять на главную страницу
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default RequireUnauth;
