import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  InputRef,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography,
  DatePicker,
  Checkbox,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DataNode } from 'antd/lib/tree';
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { organizationApi } from 'store/api/organizationApi';
import { paymentRegisterApi } from 'store/api/paymentRegisterApi';
import { IPaymentRegisterMapped } from 'store/models/IPaymentRegister';
import getFilterCheckboxSettings from 'utils/helpers/getFilterCheckboxSettings';
import { getFilterSearchSettings } from 'utils/helpers/getFilterSearchSettings';
import { stringSort } from '../../../../utils/helpers/stringSort';
import PaymentOrders from './PaymentOrders';
import DownloadFile from '../../../../utils/helpers/downloadFile';
import { apiAddressConst } from '../../../../utils/apiAddressConst';
import { API } from '../../../../utils/endpointsConst';
import RegisterStatus from './RegisterStatus';
import { toRubles } from '../../../../utils/helpers/toRubles';
import { getFilterDatePickerSettings } from '../../../../utils/helpers/getFilterDataPickerSettings';
import { IEventMapped } from '../../../../store/models/IEvent';
import PaymentsModal from './PaymentsModal';
import { Guid } from 'guid-typescript';
import TableFooter from '../TableFooter';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table/interface';
import filter from '../../../../components/Filter';
import moment from 'moment/moment';
import StatusDetail from '../../../../components/StatusDetail/StatusDetail';
import { dateSorter } from '../../../../utils/helpers/dataSort';
import { useErrorMessage } from '../../../../hooks/useErrorMessage';
import { filterString } from '../../../../utils/helpers/filterString';
import { filterCheckbox } from '../../../../utils/helpers/filterCheckbox';
import { useIsFirstRender } from '../../../../hooks/isFirstRender';

interface IConfigurationTabProps {}

interface TotalMoney {
  totalRegisters: number;
  totalPo: number;
}

interface FiltersParams {
  amount?: string;
  descr?: string[];
  ownerId?: string[];
  poStatus?: string[];
  quantity?: string;
  statusType?: string[];
}

const ConfigurationRegisters: FC<IConfigurationTabProps> = () => {
  const dateFilterInit: [string, string] = [
    moment().month(moment().month()).startOf('month').format('YYYY-MM-DD'),
    moment().month(moment().month()).endOf('month').format('YYYY-MM-DD'),
  ];
  const [dateFilter, setDateFilter] =
    useState<[string, string]>(dateFilterInit);
  const { data, isFetching, isError, error } =
    paymentRegisterApi.useGetRegistersQuery({
      Start: dateFilter[0],
      Finish: dateFilter[1],
    });
  useErrorMessage(error);
  const [isEmptyShown, setIsEmptyShown] = useState(true);
  const [filteredData, setFilteredData] = useState<IPaymentRegisterMapped[]>();
  const [filtersParamps, setFiltersParamps] = useState<
    Omit<FiltersParams, 'descr'>
  >({});
  const [filterDesc, setFilterDesc] = useState<string[]>();
  const [prevFiltersParamps, setPrevFiltersParamps] = useState<FiltersParams>(
    {}
  );
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [descFilterItems, setDescFilterItems] = useState<
    DataNode[] | undefined
  >([]);
  const isDescSet = useRef<boolean>(false);
  const [totalMoneyAmount, setTotalMoneyAmount] = useState<TotalMoney>({
    totalRegisters: 0,
    totalPo: 0,
  });
  const [tableKey, setTableKey] = useState(0);
  const incrementTableKey = () => setTableKey(tableKey + 1);
  const [isDescRendered, setIsDescRendered] = useState(true);
  const isFirstRender = useIsFirstRender();
  const { data: currentOrg } = organizationApi.useGetOrganizationQuery();

  const orgDataMapped = useMemo(() => {
    const filterItems: DataNode[] = [];
    if (data) {
      data.forEach((record) => {
        let hasAlreadyBeenThere = false;
        filterItems.forEach((district) => {
          if (district.children) {
            district.children.sort((a, b) =>
              stringSort(a.title as string, b.title as string, 'ascend')
            );
          }
          if (
            !district.children
              ? true
              : district.children?.findIndex(
                  (org) => org.key === record.ownerId
                ) !== -1
          ) {
            hasAlreadyBeenThere = true;
          }
        });
        if (hasAlreadyBeenThere) return undefined;

        const filterItemIndex = filterItems.findIndex(
          (item) => item.title === record.district
        );
        if (filterItemIndex === -1) {
          filterItems.push({
            title: record.district,
            key: record.key,
            children: [
              {
                title: record.ownerName,
                key: record.ownerId,
              },
            ],
          });
        } else {
          filterItems[filterItemIndex].children?.push({
            title: record.ownerName,
            key: record.ownerId,
          });
        }
      });
    }
    filterItems.sort((a, b) =>
      stringSort(a.title as string, b.title as string, 'ascend')
    );
    return filterItems;
  }, [data]);

  const registerTypeFilterItems = useMemo(() => {
    const mappedData = data?.map(
      (record) =>
        ({
          title: record.statusText,
          key: record.statusType, // !!! should be statusType instead
        } as DataNode)
    );
    return mappedData?.filter(
      (value, index) =>
        mappedData.findIndex((obj) => obj.key === value.key) === index
    );
  }, [data]);

  const registerPaymentsTypeFilterItems = useMemo(() => {
    const mappedData = data?.map(
      (record) =>
        ({
          title: record.poStatus,
          key: record.poStatus,
        } as DataNode)
    );
    return mappedData?.filter(
      (value, index) =>
        mappedData.findIndex((obj) => obj.key === value.key) === index
    );
  }, [data]);

  const mapDescFilterItems = useCallback(
    (_data: IPaymentRegisterMapped[] | undefined) => {
      const mappedData = _data?.map(
        (record) =>
          ({
            title:
              record.descr === '' || record.descr === undefined
                ? 'Без описания'
                : record.descr,
            key: record.descr,
          } as DataNode)
      );
      return mappedData?.filter(
        (value, index) =>
          mappedData.findIndex((obj) => obj.key === value.key) === index
      );
    },
    []
  );

  const simmarizeAllAmount = (data: IPaymentRegisterMapped[]): TotalMoney => {
    let totalAmount = 0;
    let totalPoAmount = 0;
    data.forEach((elem) => {
      totalAmount += elem.amount;
      elem.paymentOrders.forEach((po) => {
        totalPoAmount += po.amount;
      });
    });
    return { totalRegisters: totalAmount, totalPo: totalPoAmount };
  };

  const filterData = (
    filteringData: IPaymentRegisterMapped[],
    filters: FiltersParams,
    showEmpty: boolean
  ): IPaymentRegisterMapped[] => {
    return filteringData.filter(
      (record) =>
        (!showEmpty ? record.amount !== 0 : true) &&
        filterString(
          String(record.amount),
          filters.amount ? String(filters.amount) : ''
        ) &&
        filterCheckbox(record.descr, filters.descr ? filters.descr : []) &&
        filterCheckbox(
          record.poStatus,
          filters.poStatus ? filters.poStatus : []
        ) &&
        filterString(
          String(record.quantity),
          filters.quantity ? filters.quantity : ''
        ) &&
        filterCheckbox(
          record.statusType,
          filters.statusType ? filters.statusType : []
        ) &&
        filterCheckbox(record.ownerId, filters.ownerId ? filters.ownerId : [])
    );
  };

  // useEffect(() => {
  //   if (filtersParamps.descr === prevFiltersParamps.descr) {
  //     setIsDescRendered(true);
  //   }
  // }, [filtersParamps]);

  useEffect(() => {
    if (data) {
      const _filteredData = filterData(
        data,
        { ...filtersParamps, descr: filterDesc },
        isEmptyShown
      );
      setFilteredData(_filteredData);
      setTotalMoneyAmount(simmarizeAllAmount(_filteredData));
      // if (isDescRendered) {
      //   setDescFilterItems(mapDescFilterItems(_filteredData));
      //   setIsDescRendered(false);
      // }
    }
  }, [data, filtersParamps, filterDesc, isEmptyShown]);

  useEffect(() => {
    if (data) {
      setDescFilterItems(
        mapDescFilterItems(filterData(data, filtersParamps, isEmptyShown))
      );
    }
  }, [data, filtersParamps, isEmptyShown]);

  const handleDateRangeOnChange = (dateRange: [string, string]) => {
    const [start, end] = dateRange;
    if (start === '' && end === '') {
      setDateFilter(dateFilterInit);
    } else {
      setDateFilter(dateRange);
    }
  };

  const columns: ColumnsType<IPaymentRegisterMapped> = [
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip title="Скачать реестр">
            <Button
              type="default"
              shape="circle"
              size="small"
              icon={<DownloadOutlined />}
              onClick={() =>
                DownloadFile(
                  `${apiAddressConst}${API.files.paymentRegisterDownload}?paymentRegistryId=${record.paymentRegistryId}`,
                  record.fileName
                )
              }
            />
          </Tooltip>
          <PaymentsModal
            registerDate={record.outputFileDate}
            paymentRegistryId={Guid.parse(record.paymentRegistryId)}
            button={({ showModal }) => (
              <Tooltip title="Посмотреть содержимое реестра">
                <Button
                  type="default"
                  shape="circle"
                  size="small"
                  icon={<EllipsisOutlined />}
                  onClick={(e) => showModal(e)}
                />
              </Tooltip>
            )}
          />
        </div>
      ),
      width: '1%',
      className: 'ant-table-row-actions-cell',
      fixed: 'left',
    },
    {
      title: 'Дата',
      dataIndex: 'outputFileDate',
      key: 'outputFileDate',
      width: '100px',
      sorter: (a, b) =>
        dateSorter(
          moment(a.outputFileDate, 'DD.MM.YYYY'),
          moment(b.outputFileDate, 'DD.MM.YYYY')
        ),
      // ...getFilterDatePickerSettings<IPaymentRegisterMapped>('outputFileDate', {
      //   isDatePickerOpened: isCreatingTimePickerOpened,
      //   setIsDatePickerOpened: setIsCreatingTimePickerOpened,
      //   range: true,
      //   state: {
      //     get: dataRangeFilter,
      //     set: setDataRangeFilter,
      //   },
      // }),
    },
    {
      title: 'Кол-во',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '130px',
      sorter: (a, b) =>
        stringSort(String(a.quantity), String(b.quantity), 'ascend'),
      // width: '30%',
      ...getFilterSearchSettings<IPaymentRegisterMapped>('quantity', {
        searchInput: searchInput as MutableRefObject<InputRef>,
        searchText,
        searchedColumn,
        setSearchedColumn,
        setSearchText,
      }),
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      width: '130px',
      render: (_, record) => toRubles(record.amount),
      sorter: (a, b) =>
        stringSort(String(a.amount), String(b.amount), 'ascend'),
      ...getFilterSearchSettings<IPaymentRegisterMapped>('amount', {
        searchInput: searchInput as MutableRefObject<InputRef>,
        searchText,
        searchedColumn,
        setSearchedColumn,
        setSearchText,
      }),
    },
    {
      title: 'Статус реестра',
      dataIndex: 'statusType',
      key: 'statusType',
      sorter: (a, b) => stringSort(a.statusType, b.statusType, 'ascend'),
      render: (_, record) => (
        <StatusDetail id={record.paymentRegistryId}>
          <RegisterStatus status={record.statusText} />
        </StatusDetail>
      ),
      width: '130px',
      ...getFilterCheckboxSettings<IPaymentRegisterMapped>('statusType', {
        mode: 'menu',
        filters: registerTypeFilterItems,
      }),
    },
    // {
    //   title: 'Состояние расчета',
    //   dataIndex: 'poStatus',
    //   key: 'poStatus',
    //   sorter: (a, b) => stringSort(a.poStatus, b.poStatus, 'ascend'),
    //   render: (_, record) =>
    //     record.poStatus === 'Завершен' ? (
    //       <Tag icon={<CheckCircleOutlined />} color="success">
    //         {record.poStatus}
    //       </Tag>
    //     ) : (
    //       <Tag icon={<ClockCircleOutlined />} color="warning">
    //         {record.poStatus}
    //       </Tag>
    //     ),
    //   width: '130px',
    //   ...getFilterCheckboxSettings<IPaymentRegisterMapped>('poStatus', {
    //     mode: 'menu',
    //     filters: registerPaymentsTypeFilterItems,
    //   }),
    // },
    {
      title: 'Владелец',
      dataIndex: 'ownerId',
      width: '200px',
      key: 'ownerId',
      render: (_, record) => (
        <>
          <Typography>{record.ownerName}</Typography>
          <Typography.Text disabled>{record.district}</Typography.Text>
        </>
      ),
      sorter: (a, b) => stringSort(a.ownerId, b.ownerId, 'ascend'),
      ...getFilterCheckboxSettings<IPaymentRegisterMapped>('ownerId', {
        mode: 'tree',
        filters: orgDataMapped,
      }),
      defaultFilteredValue: [],
    },
    {
      title: 'Описание',
      dataIndex: 'descr',
      key: 'descr',
      width: '200px',
      // render: (_, record) => toRubles(record.descr),
      sorter: (a, b) => stringSort(String(a.descr), String(b.descr), 'ascend'),
      // ...getFilterSearchSettings<IPaymentRegisterMapped>('descr', {
      //   searchInput: searchInput as MutableRefObject<InputRef>,
      //   searchText,
      //   searchedColumn,
      //   setSearchedColumn,
      //   setSearchText,
      // }),
      ...getFilterCheckboxSettings<IPaymentRegisterMapped>('descr', {
        mode: 'menu',
        filters: descFilterItems,
      }),
    },
  ];

  if (currentOrg?.enablePO) {
    columns.splice(5, 0, {
      title: 'Состояние расчета',
      dataIndex: 'poStatus',
      key: 'poStatus',
      sorter: (a, b) => stringSort(a.poStatus, b.poStatus, 'ascend'),
      render: (_, record) =>
        record.poStatus === 'Оплачен' ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            {record.poStatus}
          </Tag>
        ) : (
          <Tag icon={<ClockCircleOutlined />} color="warning">
            {record.poStatus}
          </Tag>
        ),
      width: '130px',
      ...getFilterCheckboxSettings<IPaymentRegisterMapped>('poStatus', {
        mode: 'menu',
        filters: registerPaymentsTypeFilterItems,
      }),
    });
  }

  return (
    <>
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <DatePicker.RangePicker
          style={{
            width: 400,
          }}
          allowEmpty={[false, false]}
          value={[
            moment(dateFilter[0], 'YYYY-MM-DD'),
            moment(dateFilter[1], 'YYYY-MM-DD'),
          ]}
          onChange={(_, dateRange) => handleDateRangeOnChange(dateRange)}
        />
        <Checkbox
          onChange={(e) => setIsEmptyShown(e.target.checked)}
          checked={isEmptyShown}
        >
          Показывать пустые реестры
        </Checkbox>
      </div>
      <Table
        pagination={{
          showSizeChanger: true,
        }}
        expandable={{
          expandedRowRender: currentOrg?.enablePO
            ? (record) => (
                <PaymentOrders
                  paymentOrders={record.paymentOrders}
                  registerDate={record.outputFileDate}
                />
              )
            : undefined,
        }}
        size="small"
        sticky
        scroll={{ x: 'max-content', y: 'auto' }}
        columns={columns}
        bordered
        showSorterTooltip={false}
        dataSource={filteredData}
        loading={isFetching}
        css={`
          & .ant-pagination {
            margin-bottom: 0;
          }
        `}
        footer={() => (
          <TableFooter
            registerSum={totalMoneyAmount.totalRegisters}
            paymentOrdersSum={totalMoneyAmount.totalPo}
            showPOSum={!!currentOrg?.enablePO}
          />
        )}
        onChange={(_, _filtersParams, ___, extra) => {
          const { descr: descrParam, ...otherParams } = _filtersParams;
          // setPrevFiltersParamps(filtersParamps);
          setFilterDesc(descrParam as string[]);
          setFiltersParamps(otherParams);
          // setTotalMoneyAmount(simmarizeAllAmount(extra.currentDataSource));
        }}
      />
    </>
  );
};

export default ConfigurationRegisters;
