import { createApi } from '@reduxjs/toolkit/dist/query';
import {
  ICurrentOrg,
  IOrg,
  IOrgAll,
  IOrgListItem,
  IOrgMutation,
} from 'store/models/IOrg';
import { API } from 'utils/endpointsConst';
import { baseProtectedApi } from './baseApi';

export const organizationApi = baseProtectedApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationsAll: build.query<Array<IOrgAll>, void>({
      query: () => ({
        url: API.organization.all,
      }),
    }),
    getOrganizations: build.query<Array<IOrg>, number | void>({
      query: (id) => ({
        url: id
          ? `${API.organization.located}/${id}`
          : API.organization.located,
      }),
      providesTags: ['Organizations'],
    }),
    setOrganization: build.mutation<void, Omit<IOrgMutation, 'orgSettingsId'>>({
      query: (body) => {
        return {
          url: API.organization.set,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Organizations'],
    }),
    getOrganization: build.query<ICurrentOrg, void>({
      query: () => ({
        url: API.organization.getCurrent,
      }),
      providesTags: ['Organizations'],
    }),
    updateOrganization: build.mutation<void, IOrgMutation>({
      query: (body) => {
        return {
          url: API.organization.updateCurrent,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Organizations'],
    }),
  }),
});
