import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { mainTheme } from 'themes/mainTheme';
import * as types from 'styled-components/cssprop';
import { Provider } from 'react-redux';
import { store } from 'store';
import 'moment/locale/ru';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import { DevSupport } from '@react-buddy/ide-toolbox';
import { ComponentPreviews, useInitial } from './dev';
moment.locale('ru');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={mainTheme}>
        <ConfigProvider locale={ruRU}>
          <BrowserRouter>
            <AuthProvider>
              <DevSupport
                ComponentPreviews={ComponentPreviews}
                useInitialHook={useInitial}
              >
                <App />
              </DevSupport>
            </AuthProvider>
          </BrowserRouter>
        </ConfigProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
