import { FC, useEffect, useState } from 'react';
import { IReportParam } from '../../store/models/IReportParam';
import { reportingParameters } from './config';
import styled from 'styled-components';
import { IRenderReportDTO } from '../../store/dto/IRenderReportDTO';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { reportingActions } from '../../store/slices/reportingSlice';
import { Divider, Typography } from 'antd';
import { organizationApi } from '../../store/api/organizationApi';
import { useErrorMessage } from '../../hooks/useErrorMessage';

interface IReportingParamsProps extends React.HTMLAttributes<HTMLDivElement> {
  params: IReportParam[] | undefined;
  onChange?: () => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ReportingParams: FC<IReportingParamsProps> = ({ params, ...other }) => {
  const dispatch = useAppDispatch();
  const selectedOrgId = useAppSelector(
    (state) => state.reporting.selectedOrgId
  );

  useEffect(() => {
    if (params) {
      dispatch(
        reportingActions.initParam([
          ...params.map((param) => {
            return { name: param.nameLat, values: [] };
          }),
        ])
      );
    }
    return () => {
      dispatch(reportingActions.resetParams());
    };
  }, []);

  useEffect(() => {
    if (selectedOrgId) {
      dispatch(
        reportingActions.setParam({
          parameterNameLat: 'set_organization_id',
          values: [selectedOrgId],
        })
      );
    }
  }, [selectedOrgId]);

  const findParam = (name: string) =>
    params?.find((item) => item.nameLat === name);

  const handleChange = (nameLat: string, value: string[]): void => {
    console.log(nameLat, value);
    dispatch(
      reportingActions.setParam({ parameterNameLat: nameLat, values: value })
    );
  };

  return (
    <Root>
      {params?.map((param) =>
        param.component
          ? reportingParameters
              .find((elem) => elem.name === param.component)
              ?.render({
                paramData: findParam(param.nameLat),
                onChange: (value: string[]) =>
                  handleChange(findParam(param.nameLat)?.nameLat || '', value),
                style: { width: '100%' },
                pickerMode: reportingParameters.find(
                  (elem) => elem.name === param.component
                )?.pickerMode,
              })
          : null
      )}
    </Root>
  );
};

export default ReportingParams;
