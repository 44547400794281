import React, { useLayoutEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import PublicLayout from 'layouts/PublicLayout';
import RequireUnauth from 'components/RequireUnauth';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import authSlice from 'store/slices/authSlice';
import ProtectedLayout from 'layouts/ProtectedLayout';
import { protectedRoutes, publicRoutes } from 'routes';
import RequireAuth from 'components/RequireAuth';
import { PAYMENT_REGISTERS } from '../utils/pageConst';

function App() {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const storeRoles = useAppSelector((state) => state.auth.token.Roles) || [];
  const expirationDate = useAppSelector((state) => state.auth.token.exp);
  const [showContent, setShowContent] = useState<boolean>(false);

  useLayoutEffect(() => {
    dispatch(authSlice.actions.initCredentials());
    setShowContent(true);
  }, []);

  return (
    <>
      {showContent && (
        <Routes>
          <Route
            element={
              storeRoles?.length === 0 ? <PublicLayout /> : <ProtectedLayout />
            }
          >
            {publicRoutes.map((element) =>
              element.name === 'Авторизация' ? (
                <Route
                  path={element.path}
                  element={<RequireUnauth>{element.component}</RequireUnauth>}
                  key={element.path}
                />
              ) : (
                <Route
                  path={element.path}
                  element={element.component}
                  key={element.path}
                />
              )
            )}
            {protectedRoutes.map((element) => (
              <Route
                path={element.path}
                element={<RequireAuth>{element.component}</RequireAuth>}
                key={element.path}
              />
            ))}
            <Route
              path="/"
              element={<Navigate to={PAYMENT_REGISTERS} replace />}
            />
          </Route>
        </Routes>
      )}
    </>
  );
}

export default App;
