import { baseProtectedApi } from './baseApi';
import { API } from '../../utils/endpointsConst';
import { IReportParam } from '../models/IReportParam';
import { IReport, IReportMapped, IReportOption } from '../models/IReport';
import { IRenderReportDTO } from '../dto/IRenderReportDTO';
import { IReportParamOption } from '../models/IReportParamOption';
import { IReportStory, IReportStoryMapped } from '../models/IReportStory';
import { IPaymentRegister } from '../models/IPaymentRegister';
import moment from 'moment/moment';
import { Key } from 'react';
import { IPayments } from '../models/IPayments';
import { IDebtRegister } from '../models/IDebtRegister';
import * as url from 'url';

export const reportingApi = baseProtectedApi.injectEndpoints({
  endpoints: (build) => ({
    getParams: build.query<IReportParam[], void>({
      query: (meta) => ({
        url: API.reporting.getParams,
        method: 'GET',
      }),
    }),

    getReportParams: build.query<
      IReportParam[],
      { reportId: string | undefined }
    >({
      query: (meta) => ({
        url: API.reporting.getReportParams,
        method: 'GET',
        params: meta,
      }),
      transformResponse: (response: IReportParam[]) => {
        const hasPrecedence = (obj: any) => {
          return (
            typeof obj === 'object' &&
            'precedence' in obj &&
            typeof obj.precedence === 'number'
          );
        };

        return response.sort((a, b) =>
          hasPrecedence(a) && hasPrecedence(b) ? a.precedence - b.precedence : 0
        );
      },
    }),

    getParamOptions: build.query<IReportParamOption[], { NameLat: string }>({
      query: (meta) => ({
        url: API.reporting.getParamOptions,
        method: 'GET',
        params: meta,
      }),
    }),

    getOrgReports: build.query<
      IReportOption[],
      { ownerId: string | undefined }
    >({
      query: (meta) => ({
        url: API.reporting.getOrgReports,
        method: 'GET',
        params: meta,
      }),
      providesTags: ['Reporting'],
    }),

    getReport: build.query<IReportMapped, { id: string | undefined }>({
      query: (meta) => ({
        url: API.reporting.getReport,
        method: 'GET',
        params: meta,
      }),
      providesTags: ['Reporting'],
    }),

    renderReport: build.mutation<IReportStoryMapped, IRenderReportDTO>({
      query: (data) => ({
        url: API.reporting.renderReport,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data: newReport } = await queryFulfilled;
          const patchResult = dispatch(
            reportingApi.util.updateQueryData(
              'getReportStories',
              undefined,
              (reports) => {
                reports.unshift(newReport);
              }
            )
          );
        } catch {}
      },
      transformResponse: (response: IReportStory) => ({
        ...response,
        date: moment(response.date).format('DD.MM.YYYY, HH:mm:ss'),
        key: String(response.id),
      }),
    }),

    getReportStories: build.query<IReportStoryMapped[], void>({
      query: () => ({
        url: API.reporting.getReportStories,
        method: 'GET',
      }),
      transformResponse: (response: IReportStory[], meta, arg) =>
        response.map((story) => ({
          ...story,
          date: moment(story.date).format('DD.MM.YYYY, HH:mm:ss'),
          key: String(story.id),
        })),
      providesTags: ['ReportStories'],
    }),

    deleteReportStory: build.mutation<void, { id: string }>({
      query: (params) => ({
        url: API.reporting.deleteReportStory,
        method: 'POST',
        params,
      }),
      invalidatesTags: ['ReportStories'],
    }),
  }),
});
