import { FC } from 'react';
import styled from 'styled-components';

interface ISWithIconWrap {
  height?: string;
  width?: string;
  paddingRight?: string;
}

const SWithIconWrap = styled.div<ISWithIconWrap>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-right: ${({ paddingRight }) => paddingRight};
  & > * {
    // width: ${({ width }) => width};
    // height: ${({ height }) => height};
    width: 100%;
    height: 100%;
  }
`;

const withIconWrap = (WrappedComponent: FC, settings?: ISWithIconWrap): FC => {
  const WithIconWrap: FC = () => {
    return (
      <SWithIconWrap {...settings}>
        <WrappedComponent />
      </SWithIconWrap>
    );
  };
  return WithIconWrap;
};

export default withIconWrap;
