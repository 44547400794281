import { Button, Empty, Spin, Typography } from 'antd';
import React, { FC } from 'react';
import { eventApi } from 'store/api/eventApi';
import styled from 'styled-components';
import NotificationModal from './NotificationModal';

interface INotificationContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  handlePopoverClose?: (value: boolean) => void;
  isFetching?: boolean;
}

const NotificationContainerStyled = styled.div``;

const NotificationWrap = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
`;

const NotificationPlaceholder = styled.div`
  padding: 15px 25px;
  width: 450px;
`;

const NotificationContainer: FC<INotificationContainerProps> = ({
  children,
  handlePopoverClose = () => {},
  isFetching = false,
}) => {
  const childrenCount = React.Children.count(children);
  const [checkAllEvents] = eventApi.useCheckAllEventsMutation();

  return (
    <NotificationContainerStyled>
      <NotificationWrap>
        <Spin spinning={isFetching}>
          {childrenCount === 0 ? (
            <NotificationPlaceholder>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Новых уведомлений нет'}
              />
            </NotificationPlaceholder>
          ) : null}
          {children}
        </Spin>
      </NotificationWrap>
      <Footer>
        <NotificationModal
          button={({ showModal }) => (
            <Button
              type="link"
              size="small"
              onClick={(e) => {
                showModal(e);
                handlePopoverClose(false);
              }}
            >
              Подробнее...
            </Button>
          )}
        />
        <Button
          type="primary"
          size="small"
          onClick={() => checkAllEvents()}
          disabled={childrenCount === 0}
        >
          Закрыть все
        </Button>
      </Footer>
    </NotificationContainerStyled>
  );
};

export default NotificationContainer;
