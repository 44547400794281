import { FC } from 'react';
import styled from 'styled-components';

interface IUserInfoRowProps {
  name: string;
  value: string;
}

const Row = styled.div``;

const UserInfoRow: FC<IUserInfoRowProps> = ({ name, value }) => {
  return <Row>{`${name}: ${value}`}</Row>;
};

export default UserInfoRow;
