import { useAppSelector } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';
import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Role } from '../types';
import { AUTH } from '../utils/pageConst';

interface IRequierAuth {
  children: React.ReactNode;
  roles?: Role[];
}

const RequireAuth: FC<IRequierAuth> = ({ children, roles }) => {
  const auth = useAuth();
  const StoreRoles = useAppSelector((state) => state.auth.token.Roles) || [];
  const location = useLocation();

  if (StoreRoles === undefined || StoreRoles.length === 0) {
    // Пользователь не авторизоан, нужно перенаправлять на страницу авторизации
    return <Navigate to={AUTH} state={{ from: location }} replace />;
  }

  if (roles === undefined || roles.length === 0) {
    // Страница требует, чтобы пользователь был авторизирован, но не важно какая роль (для всех ролей)
    return <>{children}</>;
  } else if (StoreRoles.some((r) => roles.includes(r))) {
    // Страница требует, чтобы у пользователя была хотябы одна роль из необходимых для этой страницы (для определенных ролей)
    return <>{children}</>;
  } else {
    // Страница недоступна для данной роли
    return <div>Нет доступа на эту страницу</div>;
  }
};

export default RequireAuth;
