import {
  Alert,
  Button,
  Checkbox,
  Input,
  InputRef,
  message,
  Typography,
} from 'antd';
import React, {
  FC,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { AuthorizationStyled } from './AuthorizationStyled';
import { Form } from './components/Form';
import { Title } from './components/Title';
import { authApi } from 'store/api/authApi';
import { useAppDispatch } from 'hooks/redux';
import authSlice, { authActions } from 'store/slices/authSlice';
import {
  getTokenFromCookies,
  removeTokenFromCookies,
} from 'utils/helpers/cookies';
import { baseProtectedApi } from '../../store/api/baseApi';
import ServicePrivacyPolicy from './components/ServicePrivacyPolicy';
import UsageRules from './components/UsageRules';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import Icons from '../../components/Icons';

interface IAuthorizationContainer {}

const AuthorizationContainer: FC<IAuthorizationContainer> = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [login, { isLoading: isLoadingLogIn, error: loginError }] =
    authApi.useLoginMutation();
  useErrorMessage(loginError);
  const [logInError, setLogInError] = useState<Error | null>(null);
  const dispatch = useAppDispatch();
  message.config({
    maxCount: 1,
    duration: 3,
  });
  const [willRemember, setWillRemember] = useState<boolean>(true);
  const [loginInput, setLoginInput] = useState<string>('');
  const [passwordInput, setPasswordInput] = useState<string>('');
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const logInBtnRef = useRef<HTMLButtonElement>();
  const [isServicePrivicyPolicyOpened, setIsServicePrivicyPolicyOpened] =
    useState(false);
  const [isUagesRulesOpened, setIsUagesRulesOpened] = useState(false);

  useEffect(() => {
    const onPressHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && e.repeat !== true) {
        e.preventDefault();
        e.stopPropagation();
        logInBtnRef?.current?.click();
      }
    };
    if (logInBtnRef && logInBtnRef.current) {
      window.addEventListener('keydown', (e) => onPressHandler(e));
    }
    return () => {
      window.removeEventListener('keydown', (e) => onPressHandler(e));
    };
  }, []);

  const loginInputHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setShowWarning(false);
    setLoginInput(e.currentTarget.value);
  };

  const passwordInputHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setShowWarning(false);
    setPasswordInput(e.currentTarget.value);
  };

  const signInHandler = async () => {
    if (loginInput === '' || passwordInput === '') {
      setShowWarning(true);
      return 0;
    }

    try {
      const data = await login({
        userName: loginInput,
        password: passwordInput,
      }).unwrap();
      await dispatch(authActions.setCredentials(data));
      // auth.signin(['manager'], () => navigate('/'))
      if (!willRemember) {
        window.addEventListener('unload', () => {
          removeTokenFromCookies();
        });
      }
      navigate('/');
      dispatch(baseProtectedApi.util.resetApiState());
    } catch (e) {
      if (e instanceof Error) message.error('Неверный логин или пароль');
    }
  };

  return (
    <AuthorizationStyled>
      {/* {logInError && (<Alert
        message={'Неверный логин или пароль'}
        type="error"
        showIcon
        description={'Повторите попытку авторизации еще раз'}
        banner
        closable
        onClose={() => setLogInError(null)}
        style={{
          position: 'absolute',
          top: 20
        }}
      />)} */}
      <ServicePrivacyPolicy
        isOpened={isServicePrivicyPolicyOpened}
        setIsOpen={setIsServicePrivicyPolicyOpened}
      />
      <UsageRules
        isOpened={isUagesRulesOpened}
        setIsOpen={setIsUagesRulesOpened}
      />
      <Form>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 12,
            justifyContent: 'center',
            marginBottom: '35px',
          }}
        >
          <Icons.Logo />
          <Typography.Title level={4} style={{ marginBottom: '0px' }}>
            Вход
          </Typography.Title>
        </div>
        <Input
          prefix={<UserOutlined />}
          placeholder="Логин"
          value={loginInput}
          onChange={(e) => loginInputHandler(e)}
          status={showWarning && loginInput === '' ? 'error' : ''}
        />
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Пароль"
          value={passwordInput}
          onChange={(e) => passwordInputHandler(e)}
          status={showWarning && passwordInput === '' ? 'error' : ''}
        />
        <Checkbox
          onClick={() => setWillRemember(!willRemember)}
          checked={willRemember}
        >
          Запомнить меня
        </Checkbox>
        <Button
          ref={logInBtnRef as React.Ref<HTMLElement>}
          type="primary"
          block
          onClick={(e) => signInHandler()}
          loading={isLoadingLogIn}
        >
          Войти
        </Button>
        <Typography.Text
          style={{ fontSize: 12, textAlign: 'center', lineHeight: 0 }}
        >
          При авторизации, вы соглашаетесь с{' '}
          <Typography.Link onClick={() => setIsUagesRulesOpened(true)}>
            Правилами использования
          </Typography.Link>{' '}
          и{' '}
          <Typography.Link
            onClick={() => setIsServicePrivicyPolicyOpened(true)}
          >
            Политикой конфиденциальности сервиса
          </Typography.Link>
          .
        </Typography.Text>
      </Form>
    </AuthorizationStyled>
  );
};

export default AuthorizationContainer;
