import styled from 'styled-components';

export const LogoBox = styled.div`
  &&& {
    background: #032446;
    margin: -15px;
    padding: 25px 25px;
    height: 70px;
    line-height: initial;
    display: flex;
    align-items: center;
    font-size: 18px;
    border-radius: 0 15px 0 0;
    & *:first-child {
      margin-right: 8px;
    }
  }
`;
