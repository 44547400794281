import styled from 'styled-components';
import { Layout as AntLayout } from 'antd';

interface ISSidebar {}

export const SidebarStyled = styled(AntLayout.Sider)<ISSidebar>`
  &&& {
    background: #013365;
    /* overflow: auto; */
    position: sticky !important;
    top: 0;
    height: 100vh;
    z-index: 100;
    box-shadow: 16px 6px 16px -14px rgba(34, 60, 80, 0.41);
    &:after {
      content: '';
      background: #fff;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      border-radius: 0;
      z-index: -101;
    }
    & > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    & * {
      color: white;
    }
  }
`;
