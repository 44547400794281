import { localStorageConst } from './localStorageConst';

let address: string;
const addressFromLocalStorage = localStorage.getItem(
  localStorageConst.testApiAddress
);
// const addressFromEnv = process.env.REACT_APP_API_URL;
const addressFromConfig = window.config.REACT_APP_API_URL;
if (addressFromLocalStorage) {
  address = addressFromLocalStorage;
} else if (addressFromConfig) {
  address = addressFromConfig;
} else {
  address = '';
}

export const apiAddressConst = address;
