import { createApi } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import {
  IEvent,
  IEventMapped,
  IEventRequest,
  IEventRes,
  IEventResMapped,
} from 'store/models/IEvent';
import { IUser, IUserMapped } from 'store/models/IUser';
import { NotificationType } from 'types';
import { API } from 'utils/endpointsConst';
import { baseProtectedApi } from './baseApi';

export const userApi = baseProtectedApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<IUser, void>({
      query: () => ({
        url: API.users.getUser,
      }),
      providesTags: ['Users'],
    }),
    updateUser: build.mutation<void, Partial<IUser>>({
      query: (body) => {
        return {
          url: API.users.updateUser,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Users'],
    }),
  }),
});
