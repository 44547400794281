import { Modal } from 'antd';
import styled from 'styled-components';

const ModalStyled = styled(Modal)`
  &&& {
    & .ant-modal-body {
      max-height: calc(100vh - 275px);
      overflow-y: auto;
      & > * {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
`;
export default ModalStyled;
