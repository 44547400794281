import { FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Layout as AntLayout,
  message,
  notification,
  Popover,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { authApi } from 'store/api/authApi';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import authSlice from 'store/slices/authSlice';
import Progress from 'components/Progress/Progress';
import styled from 'styled-components';
import {
  BellOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { openStatusNotification } from 'utils/helpers/openStatusNotification';
import { useConnectionInit, useConnectionStart } from 'hooks/signalR';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import { toPercent } from 'utils/helpers/toPercent';
import { NotificationCard } from 'components/NotificationCard';
import NotificationContainer from 'components/NotificationContainer/NotificationContainer';
import { eventApi } from 'store/api/eventApi';
import moment from 'moment';
import { CreateAndSendType, RealEventType } from 'store/models/ISignalR';
import { NotificationType } from 'types';
import { Footer } from './components/Footer';
import { Main } from './components/Main';
import { Header } from './components/Header';
import Sidebar from './components/Sidebar';
import { ProtectedLayoutStyled } from './ProtectedLayoutStyled';
import UserPopup from '../../components/UserPopup';
import { userApi } from '../../store/api/userApi';
import { InputTestApi } from '../../components/InputTestApi';
import { apiAddressConst } from '../../utils/apiAddressConst';
import InfoPopup from '../../components/InfoPopup/InfoPopup';
import { appSettingsApi } from '../../store/api/appSettingsApi';
import { useDocumentVisable } from '../../hooks/useDocumentVisible';
import { useSessionHanlder } from '../../hooks/useSessionHanlder';
import { useSignalR, useSignalREventListener } from '../../hooks/signalr-hooks';
import { getTokenFromCookies } from '../../utils/helpers/cookies';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import signalRSlice, { signalRActions } from '../../store/slices/signalRSlice';

interface ILayoutContainer {
  children?: JSX.Element;
}

interface IProgressSocket {
  processDuration: number;
  done: number;
  all: number;
  action: 'create' | 'send';
}

const DividerStyled = styled(Divider)`
  &&& {
    margin: 0;
  }
`;

const NotificationTitle = styled(Typography.Text)`
  /* padding: 1px;
  margin: 10px; */
`;

const ProtectedLayoutContainer: FC<ILayoutContainer> = ({ children }) => {
  const [logOut] = authApi.useLoginOutMutation();
  const dispatch = useAppDispatch();
  const [progressSocket, setProgressSocket] = useState<
    IProgressSocket | undefined
  >();
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const [isInfoOpened, setIsInfoOpened] = useState(false);
  const token = useAppSelector((state) => state.auth.token);
  const { data: userMetaData } = userApi.useGetUserQuery();

  const {
    data: events,
    isFetching: isEventsFetching,
    error: eventsError,
    refetch: refetchEvents,
  } = eventApi.useGetUnreadEventsQuery({ range: 20 });
  useErrorMessage(eventsError);

  useSessionHanlder();

  const { connection } = useSignalR(`${apiAddressConst}/sendMessage`, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: () => getTokenFromCookies(),
  });

  useEffect(() => {
    if (connection) {
      dispatch(signalRActions.setConnection(connection));
    }

    return () => {
      dispatch(signalRActions.reset);
    };
  }, [connection]);

  const { connection: signalRConnection } = useAppSelector(
    (state) => state.signalR
  );

  useSignalREventListener<RealEventType>(
    signalRConnection,
    'Notification',
    (message) => {
      console.log(message);
      let timeOut;
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        refetchEvents();
      }, 3000);
      openStatusNotification(message);
    }
  );

  useEffect(() => {
    if (progressSocket?.done === progressSocket?.all) {
      setTimeout(() => {
        setProgressSocket(undefined);
      }, 2000);
    }
  }, [progressSocket]);
  const [isUserOpened, setIsUserOpened] = useState(false);

  const getCurrentYear = () => {
    return moment().year();
  };

  return (
    <ProtectedLayoutStyled hasSider>
      <Sidebar />
      <AntLayout>
        <Header>
          <Typography style={{ color: '#40A9FF', marginLeft: '20px' }}>
            {userMetaData?.userName}
          </Typography>
          {/*User profile*/}
          <Popover
            trigger={'click'}
            placement="bottomLeft"
            overlayClassName="notification-popover user-popover"
            overlayStyle={{
              padding: 0,
            }}
            visible={isUserOpened}
            onVisibleChange={(isOpen) => {
              setIsUserOpened(isOpen);
            }}
            content={<UserPopup />}
            title={<NotificationTitle>О пользователе</NotificationTitle>}
            arrowPointAtCenter
          >
            <Badge count={0} overflowCount={20}>
              <Button
                icon={<UserOutlined style={{ fontSize: '20px' }} />}
                type="link"
                style={{ marginLeft: '8px', width: 'auto', height: 'auto' }}
              />
            </Badge>
          </Popover>
          {/*Notification*/}
          <Popover
            trigger={'click'}
            placement="bottomLeft"
            overlayClassName="notification-popover"
            overlayStyle={{
              padding: 0,
            }}
            visible={isNotificationOpened}
            onVisibleChange={(isOpen) => {
              setIsNotificationOpened(isOpen);
              isOpen && refetchEvents();
            }}
            content={
              <NotificationContainer
                handlePopoverClose={setIsNotificationOpened}
                isFetching={isEventsFetching}
              >
                {events?.map((event) => (
                  <NotificationCard
                    key={event.creatingTime}
                    notificationOpt={event}
                  />
                ))}
              </NotificationContainer>
            }
            title={<NotificationTitle>Уведомления</NotificationTitle>}
            arrowPointAtCenter
          >
            <Badge
              count={events?.length === 20 ? 20 + 1 : events?.length}
              overflowCount={20}
            >
              <Button
                icon={<BellOutlined style={{ fontSize: '20px' }} />}
                type="link"
                style={{ marginLeft: '20px', width: 'auto', height: 'auto' }}
              />
            </Badge>
          </Popover>
          <Popover
            trigger={'click'}
            placement="bottomLeft"
            overlayClassName="notification-popover"
            overlayStyle={{
              padding: 0,
            }}
            visible={isInfoOpened}
            onVisibleChange={(isOpen) => {
              setIsInfoOpened(isOpen);
            }}
            content={<InfoPopup />}
            title={<NotificationTitle>О системе</NotificationTitle>}
            arrowPointAtCenter
          >
            <Badge count={0} overflowCount={20}>
              <Button
                icon={<InfoCircleOutlined style={{ fontSize: '20px' }} />}
                type="link"
                style={{ marginLeft: '20px', width: 'auto', height: 'auto' }}
              />
            </Badge>
          </Popover>
        </Header>
        <Main>
          <Outlet />
        </Main>
        <Footer>
          <div style={{ textAlign: 'end' }}>
            {`Copyright ООО "Смарт Решения" © ${getCurrentYear()}`}
          </div>
          {window.config.IS_TEST_API_SHOWN && <InputTestApi />}
        </Footer>
      </AntLayout>
    </ProtectedLayoutStyled>
  );
};

export default ProtectedLayoutContainer;
