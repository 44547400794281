import { useDocumentVisable } from './useDocumentVisible';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import authSlice from '../store/slices/authSlice';
import { message } from 'antd';
import { authApi } from '../store/api/authApi';
import { useAppDispatch, useAppSelector } from './redux';

export const useSessionHanlder = () => {
  const isDocumentVisible = useDocumentVisable();
  const [logoutTimout, setLogoutTimout] = useState<number>();
  const dispatch = useAppDispatch();
  const expirationDate = useAppSelector((state) => state.auth.token.exp);
  // const expirationDate = moment().add(10, 'seconds').unix();
  const willBeExpiredAfter = moment(Number(expirationDate) * 1000).diff(
    moment(),
    'milliseconds'
  );
  const sessionEndMessage = 'Время сессии истекло. Авторизируйтесь занова';

  useEffect(() => {
    if (isDocumentVisible) {
      if (moment(Number(expirationDate) * 1000).isBefore(moment())) {
        dispatch(authSlice.actions.deleteCredentials());
        message.warning(sessionEndMessage, 5);
      } else {
        const _logoutTimout: number = window.setTimeout(() => {
          dispatch(authSlice.actions.deleteCredentials());
          message.warning(sessionEndMessage, 5);
        }, willBeExpiredAfter);
        setLogoutTimout(_logoutTimout);
      }
    } else {
      clearTimeout(logoutTimout);
    }
    return () => {
      clearTimeout(logoutTimout);
    };
  }, [isDocumentVisible]);
};
