import { FC } from 'react';
import { IStatusDetail } from '../../store/models/IStatusDetail';
import { Empty, List, Popover, Spin } from 'antd';
import styled from 'styled-components';
import { paymentRegisterApi } from '../../store/api/paymentRegisterApi';
import StatusDetailItem from './StatusDetailItem';

interface IStatusDetailProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
}

const Container = styled.div``;

const StatusDetail: FC<IStatusDetailProps> = ({ id, children }) => {
  const { data: statusDetails, isFetching } =
    paymentRegisterApi.useGetStatusDetailsQuery({
      id,
    });

  return (
    <Popover
      placement="bottom"
      title={'История изменений'}
      content={
        <Spin spinning={isFetching}>
          <List style={{ maxHeight: '300px', overflow: 'auto' }}>
            {statusDetails ? (
              statusDetails.map((statusInfo) => (
                <StatusDetailItem data={statusInfo} key={statusInfo.id} />
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </List>
        </Spin>
      }
    >
      {children}
    </Popover>
  );
};

export default StatusDetail;
