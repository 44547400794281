import styled from 'styled-components';
import { Layout as AntLayout } from 'antd';

export const Header = styled(AntLayout.Header)`
  &&& {
    background: #fff;
    height: 70px;
    line-height: initial;
    padding: 15px 55px 15px 40px;
    box-shadow: 0px 6px 16px -13px rgba(34, 60, 80, 0.41);
    position: sticky;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //border-radius: 0 15px 15px 0;
  }
`;
