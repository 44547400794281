import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { baseProtectedApi, basePublicApi } from './api/baseApi';
import authSlice from './slices/authSlice';
import reportingSlice from './slices/reportingSlice';
import signalRSlice from './slices/signalRSlice';

export const store = configureStore({
  reducer: {
    [signalRSlice.name]: signalRSlice.reducer,
    [reportingSlice.name]: reportingSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [baseProtectedApi.reducerPath]: baseProtectedApi.reducer,
    [basePublicApi.reducerPath]: basePublicApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      baseProtectedApi.middleware,
      basePublicApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
