import Icons from 'components/Icons';
import { FC, ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { MenuStyled } from './MenuStyled';
import { protectedRoutes } from 'routes';
import { Item } from './components/Item';

const menuIcons: ReactNode[] = [
  <Icons.IconDoc />,
  <Icons.IconDoc />,
  <Icons.IconDoc />,
  <Icons.IconSettings />,
];

interface IContainerMenu {}

const MenuContainer: FC<IContainerMenu> = () => {
  const [isSelected, setIsSelected] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handlerClick = (to: string) => {
    navigate(to);
  };

  useLayoutEffect(() => {
    setIsSelected(location.pathname);
  }, [location]);

  return (
    <MenuStyled>
      {protectedRoutes.map((route, i) => (
        <Item
          isSelected={isSelected === route.path}
          key={route.name}
          icon={menuIcons[i]}
          onClick={() => handlerClick(route.path)}
        >
          {route.name}
        </Item>
      ))}
    </MenuStyled>
  );
};

export default MenuContainer;
