import { FC } from 'react';
import UserSection from './components/UserSection';
import OrganizationSection from './components/OrganizationSection';
import styled from 'styled-components';
import { Typography } from 'antd';
import Paper from 'components/Paper';

interface IDebtRegistersContainerProps {
  name: string;
}

interface IUsersProps {
  name: string;
}

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 20px;
  width: fit-content;
  margin: 0 auto;
  @media (max-width: 1290px) {
    flex-direction: column;
  }
`;

const Users: FC<IUsersProps> = ({ name }) => {
  return (
    <Wrap>
      <UserSection />
    </Wrap>
  );
};

export default Users;
