import { getTokenFromCookies } from './cookies';

const downloadFile = async (src: string, fileName: string) => {
  const token = getTokenFromCookies();

  fetch(src, {
    headers: {
      authorization: `Bearer ${getTokenFromCookies()}`,
    },
  })
    .then((res) => {
      console.log(res.headers);
      return res.blob();
    })
    .then((blob: any) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })
    .catch((err) => console.log(err));
};

export default downloadFile;
