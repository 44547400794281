import { useEffect, useRef, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

export const useSignalR = (
  hubUrl: string,
  options?: signalR.IHttpConnectionOptions
) => {
  const [connection, setConnection] = useState<HubConnection>();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const _options = options ? options : {};

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(hubUrl, _options)
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    console.log('Starting initiated!');
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log('SignalR has been connected!');
          setIsConnected(true);
        })
        .catch((err) => {
          setIsConnected(false);
          console.error(err);
        });

      return () => {
        connection.stop();
        console.log('SignalR has been disconnected!');
        setIsConnected(false);
      };
    }
  }, [connection]);

  return { connection, isConnected };
};

export const useSignalREventListener = <T>(
  connection: HubConnection | undefined,
  methodName: string,
  handler: (data: T) => void
) => {
  useEffect(() => {
    if (connection?.state === signalR.HubConnectionState.Connected) {
      connection.on(methodName, handler);
      console.log(`SignalR method "${methodName}" is being listened...`);
      return () => {
        connection.off(methodName, handler);
      };
    }
  }, [connection?.state]);
};

export const useSignalRInvoker = <ParT, RetT>(
  connection: HubConnection | undefined,
  methodName: string,
  args: ParT[],
  onSuccess: (data: RetT) => void,
  onError: (error: any) => void,
  effect?: {
    condition?: boolean | undefined;
    debounceDelay?: number | undefined;
    deps?: any[] | undefined;
  }
) => {
  const {
    condition = true,
    deps = [],
    debounceDelay = 0,
  } = effect ? effect : {};
  const [isPending, setIsPending] = useState(false);
  const countPendingReq = useRef<number>(0);
  // const abortController = useRef<AbortController | null>(null);

  useEffect(() => {
    if (condition) {
      let timeoutId: ReturnType<typeof setTimeout> | null = null;
      const executeInvoke = async () => {
        countPendingReq.current += 1;
        try {
          const result = await connection?.invoke(methodName, ...args);
          countPendingReq.current -= 1;
          if (countPendingReq.current === 0) {
            setIsPending(false);
            onSuccess(result);
          }
        } catch (error) {
          countPendingReq.current -= 1;
          if (countPendingReq.current === 0) {
            setIsPending(false);
            onError(error);
          }
        }
      };

      if (connection?.state === signalR.HubConnectionState.Connected) {
        setIsPending(true);
        // Debounce the invocation using a setTimeout function
        timeoutId = setTimeout(() => {
          executeInvoke();
        }, debounceDelay);
      }

      return () => {
        // Cancel the debounce timeout when the component unmounts or the dependencies change
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }
      };
    }
  }, [connection?.state, ...deps]);

  return { isPending };
};
