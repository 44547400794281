import { SelectParam } from './params/SelectParam';
import { DateParam } from './params/DateParam';
import { FC } from 'react';
import { InputParam } from './params/InputParam';

type ReportingParam = {
  name: string;
  render: FC;
  pickerMode?: 'multiple' | 'tags' | undefined;
};

export const reportingParameters: ReportingParam[] = [
  {
    name: 'DatePicker',
    render: DateParam,
  },
  {
    name: 'Select',
    render: SelectParam,
  },
  {
    name: 'MultipleSelect',
    render: SelectParam,
    pickerMode: 'multiple',
  },
  {
    name: 'TextField',
    render: InputParam,
  },
];
