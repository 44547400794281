import {
  DownloadOutlined,
  EditOutlined,
  MailOutlined,
  PlusCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Space, Table, Tabs, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Paper from 'components/Paper';
import { FC } from 'react';
import ConfigurationRegisters from './configuration-tab/configuration-registers';

interface IPaymentRegistersContainerProps {
  name: string;
}

const PaymentRegistersContainer: FC<IPaymentRegistersContainerProps> = ({
  name,
}) => {
  return (
    <Paper
      type="stroke"
      header={<Typography.Title level={5}>{name}</Typography.Title>}
    >
      <ConfigurationRegisters />
    </Paper>
  );
};

export default PaymentRegistersContainer;
