import { FC, useEffect, useMemo, useState } from 'react';
import { userApi } from '../../../store/api/userApi';
import { organizationApi } from '../../../store/api/organizationApi';
import { useAppSelector } from '../../../hooks/redux';
import { useIsFirstRender } from '../../../hooks/isFirstRender';
import { DataNode } from 'antd/lib/tree';
import {
  Button,
  Checkbox,
  Divider,
  Input,
  message,
  Spin,
  Switch,
  Typography,
} from 'antd';
import Paper from '../../../components/Paper';
import { Caption } from '../../../components/Caption';
import { Wrap } from './Wrap';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useErrorMessage } from '../../../hooks/useErrorMessage';
import { ReportDeliverySwitch } from '../styles';

interface IUserSectionProps {}

const UserSection: FC<IUserSectionProps> = () => {
  const {
    data: userData,
    isLoading: isLoadingUserData,
    isFetching: isFetchingUserData,
    isSuccess: isSuccessUserData,
    error: userDataError,
  } = userApi.useGetUserQuery();
  useErrorMessage(userDataError);

  const [
    updateUser,
    {
      isLoading: isLoadingUserUpdate,
      isSuccess: isSuccessUserUpdate,
      error: errorUserUpdate,
    },
  ] = userApi.useUpdateUserMutation();
  const isFirstRender = useIsFirstRender();

  const [job, setJob] = useState<string | undefined>(userData?.job);
  const [fio, setFio] = useState<string | undefined>(userData?.fio);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    userData?.phoneNumber
  );
  const [email, setEmail] = useState<string | undefined>(userData?.email);

  const handleSave = async () => {
    try {
      const promiseUpdateUser = (async () =>
        await updateUser({
          fio,
          phoneNumber,
          email,
          job,
        }))();
      const promiseUpdateOrg = (async () =>
        await updateOrg({
          mail: mail ? mail : '',
          enableMail: enableMail ? enableMail : false,
          emptySend: sendEmpty ? sendEmpty : false,
          enableZip: enableZip ? enableZip : false,
        }))();
      await Promise.all([promiseUpdateOrg, promiseUpdateUser]);
    } catch (error) {}
  };

  useEffect(() => {
    if (!isFetchingUserData) {
      setJob(userData?.job);
      setFio(userData?.fio);
      setPhoneNumber(userData?.phoneNumber);
      setEmail(userData?.email);
    }
  }, [isFetchingUserData]);

  useEffect(() => {
    if (isSuccessUserUpdate) {
      message.success('Данные пользователя успешно сохранены!');
    } else if (errorUserUpdate) {
      message.error('Возникла ошибка при сохранении данных пользователя!');
    }
  }, [isSuccessUserUpdate, errorUserUpdate]);

  const {
    data: orgData,
    isLoading: isLoadingOrgData,
    isFetching: isFetchingOrgData,
    isSuccess: isSuccessOrgData,
    error: orgDataError,
  } = organizationApi.useGetOrganizationQuery();
  useErrorMessage(orgDataError);
  const [
    updateOrg,
    {
      isLoading: isLoadingOrgUpdate,
      isSuccess: isSuccessOrgUpdate,
      error: errorOrgUpdate,
    },
  ] = organizationApi.useUpdateOrganizationMutation();

  const [mail, setMail] = useState(orgData?.mail);
  const [enableMail, setEnableMail] = useState(orgData?.enableMail);
  const [sendEmpty, setSendEmpty] = useState(orgData?.emptySend);
  const [enableZip, setEnableZip] = useState(orgData?.enableZip);

  // const handleSave = () => {
  //   updateOrg({
  //     mail: mail ? mail : '',
  //     enableMail: enableMail ? enableMail : false,
  //     emptySend: sendEmpty ? sendEmpty : false,
  //     enableZip: enableZip ? enableZip : false,
  //   });
  // };

  useEffect(() => {
    if (!isFetchingOrgData) {
      setMail(orgData?.mail);
      setEnableMail(orgData?.enableMail);
      setEnableZip(orgData?.enableZip);
      setSendEmpty(orgData?.emptySend);
    }
  }, [isFetchingOrgData]);

  useEffect(() => {
    if (isSuccessOrgUpdate) {
      message.success('Данные организации успешно сохранены!');
    } else if (errorOrgUpdate) {
      message.success('Возникла ошибка при сохранении данных организации!');
    }
  }, [isSuccessOrgUpdate, errorOrgUpdate]);

  return (
    <Paper
      type="stroke"
      header={<Typography.Title level={5}>Настройки</Typography.Title>}
      // css={{ width: 'fit-content', margin: '0 auto' }}
    >
      {isLoadingUserData || isLoadingOrgData ? (
        <Wrap>
          <Spin style={{ margin: '10px 0' }} tip={'Загрузка...'} />
        </Wrap>
      ) : (
        <Wrap>
          <Typography.Title
            level={5}
            style={{ color: '#666', marginBottom: 0 }}
          >
            Пользователь
          </Typography.Title>
          <Caption text="Должность">
            <Input
              placeholder="Должность"
              value={job}
              onChange={(e) => setJob(e.target.value)}
              disabled={isFetchingUserData}
            />
          </Caption>
          <Caption text="ФИО">
            <Input
              placeholder="ФИО"
              value={fio}
              onChange={(e) => setFio(e.target.value)}
              disabled={isFetchingUserData}
            />
          </Caption>
          <Caption text="Тел.">
            <Input
              placeholder="Тел."
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              disabled={isFetchingUserData}
            />
          </Caption>
          <Caption text="E-mail">
            <Input
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isFetchingUserData}
            />
          </Caption>
          <Divider style={{ margin: '0 -24px', width: 'calc(100% + 48px)' }} />
          <ReportDeliverySwitch>
            <Typography.Title
              level={5}
              style={{ color: '#666', marginBottom: 0 }}
            >
              Доставка реестров
            </Typography.Title>
            <Switch
              checked={enableMail}
              onChange={(checked) => setEnableMail(checked)}
            />
          </ReportDeliverySwitch>
          {enableMail && (
            <>
              <Caption text="E-mail">
                <Input
                  placeholder="E-mail"
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setMail(e.currentTarget.value)
                  }
                  value={mail}
                />
              </Caption>
              <div>
                <Checkbox
                  checked={sendEmpty}
                  onChange={(e: CheckboxChangeEvent) =>
                    setSendEmpty(e.target.checked)
                  }
                >
                  Доставлять пустые реестры
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={enableZip}
                  onChange={(e: CheckboxChangeEvent) =>
                    setEnableZip(e.target.checked)
                  }
                >
                  Использовать сжатие реестров
                </Checkbox>
              </div>
            </>
          )}
          <Divider style={{ margin: '0 -24px', width: 'calc(100% + 48px)' }} />
          <Button
            type={'primary'}
            onClick={handleSave}
            loading={
              isLoadingOrgData ||
              isFetchingOrgData ||
              isLoadingUserData ||
              isFetchingUserData
            }
          >
            Сохранить
          </Button>
        </Wrap>
      )}
    </Paper>
  );
};

export default UserSection;
