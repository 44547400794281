import styled from 'styled-components';

interface IUploadedFileStyled {}

export const UploadedFileStyled = styled.div<IUploadedFileStyled>`
  background-color: #fafafa;
  //box-sizing: 3px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;
