import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button, Spin, Tooltip } from 'antd';
import { FC } from 'react';
import { API } from 'utils/endpointsConst';
import downloadFile from 'utils/helpers/downloadFile';
import { UploadedFileStyled } from './UploadedFileStyled';
import { useAppSelector } from '../../../../hooks/redux';
import { apiAddressConst } from '../../../../utils/apiAddressConst';

interface IUploadedFileContainerProps {
  fileIcon: React.ReactNode;
  fileName: string;
  fileId?: string;
  isLoading?: boolean;
  deleteFile?: () => void;
}

const fileNameTrimmer = (name: string) =>
  name.slice(0, 35) + (name.length > 35 ? '...' : '');

const UploadedFileContainer: FC<IUploadedFileContainerProps> = ({
  fileIcon,
  fileName,
  fileId,
  isLoading = false,
  deleteFile = () => {},
}) => {
  const downloadHandler = (id: string) => {
    downloadFile(`${apiAddressConst}${API.files.download}?id=${id}`, fileName);
  };

  const renderNode = (
    <UploadedFileStyled>
      <div
        css={`
          font-size: 35px;
          color: #40a9ff;
          margin-right: 16px;
        `}
      >
        {isLoading ? <Spin size="large" /> : fileIcon}
      </div>
      <div
        css={`
          font-size: 16px;
          font-weight: 400;
          ${isLoading &&
          `
              color: #949494;
            `}
        `}
      >
        {fileNameTrimmer(fileName)}
      </div>
      <div
        css={`
          flex-grow: 1;
        `}
      />
      <Button
        icon={<DownloadOutlined />}
        disabled={isLoading}
        shape="circle"
        type="link"
        onClick={() => fileId && downloadHandler(fileId)}
      />
      <Button
        icon={<DeleteOutlined />}
        shape="circle"
        type="link"
        danger
        onClick={deleteFile}
      />
    </UploadedFileStyled>
  );

  if (fileName.length > 35) {
    return <Tooltip title={fileName}>{renderNode}</Tooltip>;
  } else {
    return <>{renderNode}</>;
  }
};

export default UploadedFileContainer;
