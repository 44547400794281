import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, InputProps, InputRef, Space } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import DatePickerFilterDropdown from 'components/DatePickerFilterDropdown';
import moment, { MomentInput } from 'moment';
import React, { MutableRefObject } from 'react';
import { isDateInRange } from './isDataInRange';

// type HandleSearchType = <T,>(
//   selectedKeys: string[],
//   confirm: (param?: FilterConfirmProps) => void,
//   dataIndex: keyof T,
// ) => void;

export interface IDataFilterState {
  get: React.Key[];
  set: React.Dispatch<React.SetStateAction<React.Key[]>>;
}

interface IGetFilterDatePickerSettingsPropsConfig {
  // inputRef: MutableRefObject<InputRef>;
  // filteredColumn: any;
  // setFilteredColumn: (searchedColumn: any) => any;
  // filterValue: any;
  // setFilterValue: (searchText: any) => any;
  isDatePickerOpened: boolean;
  setIsDatePickerOpened: (isDataPickerOpened: any) => any;
  state?: IDataFilterState;
  type?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  range?: boolean;
  showTime?: boolean;
}

export const getFilterDatePickerSettings = <T,>(
  dataIndex: keyof T,
  config: IGetFilterDatePickerSettingsPropsConfig
): ColumnType<T> => {
  const {
    type = 'date',
    // inputRef,
    // filterValue,
    // setFilterValue,
    // filteredColumn,
    // setFilteredColumn,
    isDatePickerOpened,
    setIsDatePickerOpened,
    range = false,
    showTime = false,
    state,
  } = config;
  type DataIndex = keyof T;

  let handleFilter: (
    values: string | number | boolean,
    record: T,
    state?: IDataFilterState
  ) => boolean;
  let calendarFormString: string;
  switch (type) {
    case 'month':
      calendarFormString = 'MMMM YYYY';
      handleFilter = (value, record) =>
        String(record[dataIndex])
          .toString()
          .toLowerCase()
          .includes(
            moment(value as string)
              .format(calendarFormString)
              .toString()
          );
      break;

    default:
      calendarFormString = showTime ? 'DD.MM.YYYY, hh:mm:ss' : 'DD.MM.YYYY';
      handleFilter = (value, record) => {
        return isDateInRange(
          moment(String(record[dataIndex]), calendarFormString),
          state ? moment(state?.get[0] as MomentInput) : undefined,
          state ? moment(state?.get[1] as MomentInput) : undefined
        );
      };
      break;
  }

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => {
      return (
        <DatePickerFilterDropdown
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          dataIndex={dataIndex as string}
          type={type}
          isDatePickerOpened={isDatePickerOpened}
          setIsDatePickerOpened={setIsDatePickerOpened}
          clearFilters={clearFilters}
          confirm={confirm}
          visible={visible}
          range={range}
          showTime={showTime}
          onCalendarChange={(data) => state?.set(data)}
        />
      );
    },
    onFilter: handleFilter,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setIsDatePickerOpened(true);
      } else {
        setIsDatePickerOpened(false);
      }
    },
  };
};
