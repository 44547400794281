import React, {
  ChangeEvent,
  FC,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIsFirstRender } from '../../../hooks/isFirstRender';
import { debtRegisterApi } from '../../../store/api/debtRegisterApi';
import { useErrorMessage } from '../../../hooks/useErrorMessage';
import DragAndDrop, {
  IFileState,
} from '../../../components/DragAndDrop/DragAndDropContainer';
import { Button, DatePicker, Input, message, Modal, Typography } from 'antd';
import { Guid } from 'guid-typescript';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ModalStyled from '../../../components/Modal/Modal';
import { Caption } from '../../../components/Caption';
import { apiAddressConst } from '../../../utils/apiAddressConst';
import { API } from '../../../utils/endpointsConst';
import { IPaymentRegister } from '../../../store/models/IPaymentRegister';
import ReportingParams from '../../../components/ReportingParams/ReportingParams';
import { reportingApi } from '../../../store/api/reportingApi';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { reportingActions } from '../../../store/slices/reportingSlice';
import { organizationApi } from '../../../store/api/organizationApi';

interface IReportRenderModalProps {
  button: FC<{ showModal: MouseEventHandler }>;
  selectedReportId: string;
  selectedReportName: string;
}

const ReportRenderModal: FC<IReportRenderModalProps> = ({
  button,
  selectedReportName,
  selectedReportId,
}) => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const isFirstRender = useIsFirstRender();
  const [hasBeenReset, setHasBeenReset] = useState(false);
  const [
    saveDebtRegister,
    { isSuccess: isSaveSuccess, isLoading: isSaving, error: saveError },
  ] = debtRegisterApi.useSaveMutation();
  useErrorMessage(saveError);
  const clickCount = useRef(0);

  //States for inputs
  const { params } = useAppSelector((state) => state.reporting);

  const dispatch = useAppDispatch();

  const { data: parameters, refetch: refetchParameters } =
    reportingApi.useGetReportParamsQuery({
      reportId: selectedReportId,
    });

  const [
    renderReport,
    {
      data: reportDataSet,
      isLoading: isReportRendering,
      error: reportRenderError,
      isSuccess: reportRenderSuccess,
    },
  ] = reportingApi.useRenderReportMutation();
  useErrorMessage(reportRenderError);

  useEffect(() => {
    if (reportRenderSuccess) {
      setIsModalOpened(false);
      // message.success('Отчет добавлен в очередь на формирование!');
    }
  }, [reportRenderSuccess]);

  useEffect(() => {
    if (isConfirmed) {
      setIsModalOpened(false);
      setIsConfirmed(false);
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (!isFirstRender && !hasBeenReset) {
      setIsSaved(false);
      clickCount.current = 0;
    }
  }, [params]);

  useEffect(() => {
    setHasBeenReset(false);
  });

  // useEffect(() => {
  //   if (isSaveSuccess) {
  //     dispatch(reportingActions.resetParams());
  //   }
  // }, [isSaveSuccess]);

  const showModal = () => {
    setIsModalOpened(true);
    setIsRendered(true);
  };

  const submitHandler = () => {
    if (!checkIsValid()) return 0;
    renderReport({ repId: selectedReportId, renderData: params });
    if (!reportRenderError) clickCount.current++;
  };

  const checkIsValid = () => {
    const unsetParam = params.find((param) => param.values.length === 0);
    if (unsetParam) return false;
    return true;
  };

  const handleOk = () => {
    if (checkIsValid()) {
      submitHandler();
    }
  };

  const handleCancel = () => {
    if (!isSaved) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Вы уверены, что хотите закрыть это окно?',
        content: (
          <Typography.Text type="secondary" style={{ marginTop: '30px' }}>
            Несохраненные данные будут утеряны!
          </Typography.Text>
        ),
        onOk: () => {
          setIsConfirmed(true);
        },
        onCancel: () => {},
      });
    } else {
      setIsConfirmed(true);
    }
  };

  const resetInputs = () => {
    dispatch(reportingActions.resetParams());

    setHasBeenReset(true);
  };

  return (
    <>
      {button({ showModal })}
      {isRendered && (
        <ModalStyled
          destroyOnClose
          width={400}
          style={{ top: '20px' }}
          wrapClassName={'my-class'}
          afterClose={() => {
            setIsSaved(true);
          }}
          title={selectedReportName}
          visible={isModalOpened}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button onClick={handleCancel}>Отмена</Button>,
            <Button
              onClick={handleOk}
              type="primary"
              disabled={!checkIsValid() || clickCount.current >= 1}
              loading={isSaving}
            >
              Сформировать
            </Button>,
          ]}
        >
          <ReportingParams params={parameters} />
        </ModalStyled>
      )}
    </>
  );
};

export default ReportRenderModal;
