// Define a state variable to store the page visibility
import React, { useEffect } from 'react';

export const useDocumentVisable = () => {
  const [isVisible, setIsVisible] = React.useState(!document.hidden);

  // Define a function to handle the visibility change
  const handleVisibilityChange = () => {
    setIsVisible(!document.hidden);
  };

  // Add an event listener for visibility change on mount
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isVisible;
};
