import { useEffect } from 'react';
import { message, Modal, Typography } from 'antd';
import errorImage from 'assets/imgs/503-error.png';

export const useErrorMessage = (error: any) => {
  useEffect(() => {
    if (error) {
      console.log(error);
      if ('originalStatus' in error) {
        message.error(`Ошибка ${error.originalStatus}: ${error.data || ''}`);
      } else {
        // message.error('API недоступен!');
        Modal.destroyAll();
        Modal.error({
          title: 'Сервер временно недоступен',
          content: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '40px',
                paddingBottom: '20px',
                marginLeft: '-20px',
              }}
            >
              <img src={errorImage} width={400} />
              <Typography style={{ fontWeight: 'bold' }}>
                Попробуйте перезагрузить страницу.
              </Typography>
            </div>
          ),
          closable: false,
          maskClosable: false,
          okButtonProps: { style: { display: 'none' } },
          className: 'error-modal',
          wrapClassName: 'error-wrap-modal',
        });
      }
    }
  }, [error]);
};
