import React, { FC, useMemo } from 'react';
import { Caption } from '../../Caption';
import { Select } from 'antd';
import { ReportingParameterProps } from './types';
import { reportingApi } from '../../../store/api/reportingApi';
import { useErrorMessage } from '../../../hooks/useErrorMessage';

type ISelectParamProps = Omit<React.ComponentProps<typeof Select>, 'onChange'> &
  ReportingParameterProps;

export const SelectParam: FC<ISelectParamProps> = ({
  paramData,
  onChange = () => {},
  pickerMode,
  ...props
}) => {
  const {
    data: options,
    isLoading: isOptionsLoading,
    error: optionsError,
  } = reportingApi.useGetParamOptionsQuery(
    { NameLat: paramData?.nameLat || '' },
    { skip: !paramData }
  );
  useErrorMessage(optionsError);

  const optionsMapped = useMemo(() => {
    return options?.map((_option) => ({
      value: _option.id,
      label: _option.desc,
    }));
  }, [options]);

  return (
    <Caption text={paramData?.name || ''}>
      <Select
        showArrow
        mode={pickerMode}
        options={optionsMapped}
        loading={isOptionsLoading}
        onChange={(value) =>
          onChange(
            pickerMode === 'multiple'
              ? (value as string[])
              : ([value] as string[])
          )
        }
        {...props}
      />
    </Caption>
  );
};
