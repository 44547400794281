import { InputProps, Select as SelectAnt, SelectProps, TreeSelect } from 'antd';
import { TreeSelectProps } from 'rc-tree-select';
import { ChangeEventExtra } from 'rc-tree-select/lib/TreeSelect';
import { FC, ReactNode, Ref, useEffect } from 'react';
import { organizationApi } from 'store/api/organizationApi';
import Select from './Select';
import { stringSort } from '../utils/helpers/stringSort';

type IOrganizationSelectProps = TreeSelectProps &
  React.HTMLAttributes<HTMLElement> & {
    caption?: string;
    multiple?: boolean;
    type?: 'all' | 'located';
  };

interface TreeDataNode {
  title: string | undefined;
  value: string | undefined;
  key: React.Key | undefined;
  isLeaf?: boolean | undefined;
  children?: TreeDataNode[] | undefined;
  selectable?: boolean | undefined;
}

const { SHOW_PARENT } = TreeSelect;

const OrganizationSelect: FC<IOrganizationSelectProps> = ({
  placeholder = 'Организация',
  caption = 'Организация',
  multiple = false,
  onChange,
  type = 'located',
  value,
  ...other
}) => {
  const dataLocatedOrg = organizationApi.useGetOrganizationsQuery();
  const dataAllOrg = organizationApi.useGetOrganizationsAllQuery();

  let orgs: typeof dataAllOrg | typeof dataLocatedOrg;
  if (type === 'all') {
    orgs = dataAllOrg;
  } else {
    orgs = dataLocatedOrg;
  }
  const { data: dataOrg, isLoading: isLoadingOrg, error: errorOrg } = orgs;

  let treeData: TreeDataNode[];

  if (multiple) {
    treeData = [
      {
        title: 'Все районы',
        value: 'all',
        key: 'all',
        isLeaf: true,
        children: dataOrg?.map((district) => ({
          title: district.name,
          key: district.id,
          value: district.id,
          children: district.organizationList
            .map((org) => ({
              title: org.name,
              key: org.id,
              value: org.id,
            }))
            .sort((a, b) => stringSort(a.title, b.title, 'ascend')),
        })),
      },
    ];
  } else {
    treeData = dataOrg
      ? dataOrg.map((district) => ({
          title: district.name,
          key: district.id,
          value: district.id,
          selectable: false,
          children: district.organizationList
            .map((org) => ({
              title: org.name,
              key: org.id,
              value: org.id,
            }))
            .sort((a, b) => stringSort(a.title, b.title, 'ascend')),
        }))
      : [];
  }

  const onChangeHandler = (
    value: any,
    labeList: React.ReactNode[],
    extra: ChangeEventExtra
  ) => {
    if (onChange === undefined || dataOrg === undefined) return 0;

    if (!multiple) {
      return onChange(value, labeList, extra);
    } else {
      const valueListMapped: string[] = [];
      (value as Array<string>).forEach((oneValue) => {
        if (oneValue === 'all') {
          dataOrg.forEach((district) => {
            valueListMapped.push(
              ...district.organizationList.map((elem) =>
                elem.id ? elem.id : ''
              )
            );
          });
        } else {
          const district = dataOrg.find((district) => district.id === oneValue);
          if (district) {
            valueListMapped.push(
              ...district.organizationList.map((elem) =>
                elem.id ? elem.id : ''
              )
            );
          } else {
            valueListMapped.push(oneValue);
          }
        }
      });
      onChange(valueListMapped, labeList, extra);
    }
  };

  return (
    <TreeSelect
      value={value}
      showArrow
      placeholder={placeholder}
      treeData={treeData}
      showCheckedStrategy={SHOW_PARENT}
      style={{ width: '100%' }}
      treeCheckable={multiple}
      allowClear
      treeDefaultExpandedKeys={['all', value]}
      maxTagCount="responsive"
      onChange={onChangeHandler}
      {...other}
    />
  );
};

export default OrganizationSelect;
