import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HubConnection } from '@microsoft/signalr';

interface ISignalRState {
  connection: HubConnection | undefined;
}

const initialState: ISignalRState = {
  connection: undefined,
};

const signalRSlice = createSlice({
  name: 'signalR',
  initialState,
  reducers: {
    setConnection: (state, action: PayloadAction<HubConnection>) => {
      state.connection = action.payload;
    },
    reset: (state, action: PayloadAction<void>) => {
      state = initialState;
    },
  },
});

export default signalRSlice;
export const signalRActions = signalRSlice.actions;
