import moment from 'moment';
import {
  IDebtRegister,
  IDebtRegisterMapped,
  ISaveDebtRegisterReq,
} from 'store/models/IDebtRegister';
import { API } from 'utils/endpointsConst';
import { baseProtectedApi } from './baseApi';

export const debtRegisterApi = baseProtectedApi.injectEndpoints({
  endpoints: (build) => ({
    getAll: build.query<
      IDebtRegisterMapped[],
      { Start: string; Finish: string }
    >({
      query: (params) => ({
        url: API.debtRegister.getAll,
        method: 'GET',
        params,
      }),
      transformResponse: (response: IDebtRegister[], meta, arg) =>
        response.map((debtRegister) => ({
          ...debtRegister,
          creatingDate: moment(debtRegister.creatingDate).format(
            'DD.MM.YYYY, HH:mm:ss'
          ),
          billingPeriod: moment(debtRegister.billingPeriod).format('MMMM YYYY'),
          key: String(debtRegister.debtRegisterId),
        })),
      providesTags: ['DebtRegisters'],
    }),
    save: build.mutation<void, ISaveDebtRegisterReq>({
      query: (req) => ({
        url: API.debtRegister.upload,
        method: 'POST',
        body: req,
      }),
      invalidatesTags: ['DebtRegisters'],
    }),
  }),
});
