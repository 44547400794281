import { FC } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import { appSettingsApi } from '../../store/api/appSettingsApi';

interface IInfoPopupProps {}

const Wrap = styled.div`
  width: 300px;
  padding: 10px 25px;
`;

const InfoPopup: FC<IInfoPopupProps> = () => {
  const { data: apiVersionArr } = appSettingsApi.useGetVersionQuery();

  return (
    <Wrap>
      <Typography>{`Web-app: ${window.config.REACT_APP_VERSION}v`}</Typography>
      <Typography>
        {`API: ${apiVersionArr?.version ? apiVersionArr.version : '?.?.?'}v`}{' '}
      </Typography>
    </Wrap>
  );
};

export default InfoPopup;
