import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IReportingState {
  // selectedReportId: string;
  // selectedReportFileId: string;
  params: { parameterNameLat: string; values: string[] }[];
  selectedOrgId: string;
  // reportDataSet: { [key: string]: any } | null;
}

const initialState: IReportingState = {
  params: [],
  selectedOrgId: '',
};

const reportingSlice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    // setSelectedReportId: (state, action: PayloadAction<string>) => {
    //   state.selectedReportId = action.payload;
    // },
    // setSelectedReportFileId: (state, action: PayloadAction<string>) => {
    //   state.selectedReportFileId = action.payload;
    // },
    setParam: (
      state,
      action: PayloadAction<{ parameterNameLat: string; values: string[] }>
    ) => {
      const elemIndex = state.params.findIndex(
        (param) => param.parameterNameLat === action.payload.parameterNameLat
      );
      if (elemIndex !== -1) {
        state.params[elemIndex] = action.payload;
      } else {
        state.params.push(action.payload);
      }
    },
    initParam: (
      state,
      action: PayloadAction<{ name: string; values: string[] }[]>
    ) => {
      state.params = action.payload.map((param) => ({
        parameterNameLat: param.name,
        values: param.values,
      }));
    },
    resetParams: (state, action: PayloadAction<void>) => {
      state.params = initialState.params;
    },
    setSelectedOrgId: (state, action: PayloadAction<string>) => {
      state.selectedOrgId = action.payload;
    },
  },
});

export default reportingSlice;
export const reportingActions = reportingSlice.actions;
