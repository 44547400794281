import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Modal,
  Space,
  Switch,
  Typography,
  Input as InputAnt,
  Input,
  DatePicker,
  message,
} from 'antd';
import { Caption } from 'components/Caption';
import DragAndDrop, {
  IFileState,
} from 'components/DragAndDrop/DragAndDropContainer';
import ModalStyled from 'components/Modal/Modal';
import OrganizationSelect from 'components/OrganizationSelect';
import { useIsFirstRender } from 'hooks/isFirstRender';
import React, {
  ChangeEvent,
  FC,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { paymentRegisterApi } from 'store/api/paymentRegisterApi';
import { IPaymentRegister } from 'store/models/IPaymentRegister';
import { API } from 'utils/endpointsConst';
import { useAppSelector } from 'hooks/redux';
import { apiAddressConst } from 'utils/apiAddressConst';
import { debtRegisterApi } from '../../store/api/debtRegisterApi';
import { Guid } from 'guid-typescript';
import filter from '../../components/Filter';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import { organizationApi } from '../../store/api/organizationApi';

interface IConfigurationModalProps {
  button: FC<{ showModal: MouseEventHandler }>;
  type?: 'create' | 'update';
  formData?: IPaymentRegister;
}

const ConfigurationFileModal: FC<IConfigurationModalProps> = ({
  button,
  type = 'create',
  formData,
}) => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [isRendered, setIsRendered] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const isFirstRender = useIsFirstRender();
  const [hasBeenReset, setHasBeenReset] = useState(false);
  const [
    saveDebtRegister,
    { isSuccess: isSaveSuccess, isLoading: isSaving, error: saveError },
  ] = debtRegisterApi.useSaveMutation();
  useErrorMessage(saveError);

  const { data: currentOrg } = organizationApi.useGetOrganizationQuery();

  //States for inputs
  const [description, setDescription] = useState<string | undefined>();
  const [files, setFiles] = useState<IFileState[] | []>([]);
  const [billingPeriod, setBillingPeriod] = useState<Date>();
  const [selectedOrganization, setSelectedOrganization] = useState<string>();

  useEffect(() => {
    if (currentOrg) {
      setSelectedOrganization(currentOrg.orgId);
    }
  }, [currentOrg]);

  useEffect(() => {
    if (isConfirmed) {
      setIsModalOpened(false);
      setIsConfirmed(false);
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (!isFirstRender && !hasBeenReset) {
      setIsSaved(false);
    }
  }, [description, billingPeriod, files, selectedOrganization]);

  useEffect(() => {
    setHasBeenReset(false);
  });

  useEffect(() => {
    if (isSaveSuccess) {
      setIsModalOpened(false);
      message.success(
        type === 'create'
          ? 'Реестр успешно добавлен!'
          : 'Реестр успешно изменен!'
      );
    }
  }, [isSaveSuccess]);

  const showModal = () => {
    setIsModalOpened(true);
    setIsRendered(true);
  };

  const submitHandler = () => {
    if (!checkIsValid()) return 0;
    saveDebtRegister({
      period: billingPeriod!,
      fileIds: files.map((file) => file.id as Guid),
      desc: description!,
      orgId: selectedOrganization!,
    });
  };

  const checkAreFilesValid = () => {
    if (files.length === 0) return false;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.isLoading) return false;
      if (!Guid.isGuid(file.id)) return false;
    }
    return true;
  };

  const checkIsValid = () => {
    if (!billingPeriod) return false;
    if (!selectedOrganization) return false;
    if (!checkAreFilesValid()) return false;
    return true;
  };

  const handleOk = () => {
    if (checkIsValid()) {
      submitHandler();
    }
  };

  const handleCancel = () => {
    if (!isSaved) {
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Вы уверены, что хотите закрыть это окно?',
        content: (
          <Typography.Text type="secondary" style={{ marginTop: '30px' }}>
            Несохраненные данные будут утеряны!
          </Typography.Text>
        ),
        onOk: () => {
          setIsConfirmed(true);
          if (type === 'update') rollbackToInit();
        },
        onCancel: () => {},
      });
    } else {
      setIsConfirmed(true);
    }
  };

  const resetInputs = () => {
    setDescription(undefined);
    setFiles([]);
    setBillingPeriod(undefined);
    setSelectedOrganization(currentOrg ? currentOrg.orgId : undefined);

    setHasBeenReset(true);
  };

  const rollbackToInit = () => {
    setDescription(undefined);
    setFiles([]);
    setBillingPeriod(undefined);
    setSelectedOrganization(currentOrg ? currentOrg.orgId : undefined);
  };

  return (
    <>
      {button({ showModal })}
      {isRendered && (
        <ModalStyled
          destroyOnClose={type === 'create'}
          style={{ top: '20px' }}
          wrapClassName={'my-class'}
          afterClose={() => {
            if (type === 'create') {
              resetInputs();
            }
            setIsSaved(true);
          }}
          title={
            type === 'create'
              ? 'Добавление реестра задолженности'
              : 'Изменения реестра задолженности'
          }
          visible={isModalOpened}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button onClick={handleCancel}>Отмена</Button>,
            <Button
              onClick={handleOk}
              type="primary"
              disabled={!checkIsValid()}
              loading={isSaving}
            >
              Сохранить
            </Button>,
          ]}
        >
          <DragAndDrop
            showInitFile={type === 'update'}
            files={{ get: files, set: setFiles }}
            style={{ marginBottom: '20px' }}
            action={`${apiAddressConst}${API.files.upload}`}
          />
          {currentOrg?.orgId === '-1' ? (
            <Caption text="Владелец">
              <OrganizationSelect
                onChange={(value) => setSelectedOrganization(value)}
                value={selectedOrganization}
                placeholder="Владелец"
              />
            </Caption>
          ) : null}
          <Caption text="Расчетный период">
            <DatePicker
              onChange={(value) => setBillingPeriod(value?.toDate())}
              picker={'month'}
              style={{
                width: '100%',
              }}
              // value={organization}
              placeholder="Расчетный период"
            />
          </Caption>
          <Caption text="Комментарий">
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setDescription(e.target.value)
              }
              value={description}
              placeholder="Комментарий"
            />
          </Caption>
        </ModalStyled>
      )}
    </>
  );
};

export default ConfigurationFileModal;
