import { Dispatch, FC, SetStateAction } from 'react';
import { Empty, Modal, Spin } from 'antd';
import ReactMarkdown from 'react-markdown';
import { appSettingsApi } from '../../../store/api/appSettingsApi';
import { Wrap } from '../../users/components/Wrap';

interface IUsageRulesProps {
  isOpened: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const UsageRules: FC<IUsageRulesProps> = ({ isOpened = false, setIsOpen }) => {
  const { data, isLoading } = appSettingsApi.useGetServiceUsageRulesQuery();
  console.log(data?.text);

  const renderNode = data?.text ? (
    <ReactMarkdown>{data.text}</ReactMarkdown>
  ) : (
    <Empty description={false} />
  );
  const loader = (
    <Wrap>
      <Spin style={{ margin: '10px 0' }} tip={'Загрузка...'} />
    </Wrap>
  );

  return (
    <Modal
      visible={isOpened}
      onCancel={() => setIsOpen(false)}
      footer={null}
      title={'Правила использования сервиса'}
      wrapClassName={'rules-modal-wrap'}
    >
      <>{isLoading ? loader : renderNode}</>
    </Modal>
  );
};

export default UsageRules;
