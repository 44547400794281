import {
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  CloseOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  InfoCircleOutlined,
  InfoCircleTwoTone,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import moment from 'moment';
import { FC } from 'react';
import { eventApi } from 'store/api/eventApi';
import { IEvent } from 'store/models/IEvent';
import styled from 'styled-components';
import { NotificationOpt, NotificationType } from 'types';

interface INotificationCardProps extends React.HTMLAttributes<HTMLDivElement> {
  notificationOpt: IEvent;
}

const NotificationCardStyled = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 15px 25px;
  width: 450px;
  position: relative;
`;

const Status = styled.div`
  margin-right: 25px;
  font-size: 25px;
  position: relative;
  top: -6px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const Discription = styled.div`
  font-size: 14px;
`;

const Main = styled.div`
  flex-grow: 1;
`;

const NotificationCard: FC<INotificationCardProps> = ({ notificationOpt }) => {
  const { eventId, messageType, creatingTime, eventTitle } = notificationOpt;
  const [checkEvent] = eventApi.useCheckEventMutation();

  let statusIcon: React.ReactNode;
  switch (messageType.toLowerCase() as NotificationType) {
    case 'success':
      statusIcon = <CheckCircleTwoTone twoToneColor="#5cc72e" />;
      break;
    case 'error':
      statusIcon = <CloseCircleTwoTone twoToneColor={'#ff8281'} />;
      break;
    case 'warning':
      statusIcon = <ExclamationCircleTwoTone twoToneColor={'#fcc66c'} />;
      break;
    default:
      statusIcon = <InfoCircleTwoTone twoToneColor={'#48a1ff'} />;
      break;
  }

  return (
    <NotificationCardStyled>
      <Status>{statusIcon}</Status>
      <Main>
        <Header>
          <Title>{moment(creatingTime).format('DD.MM.YYYY, HH:mm:ss')}</Title>
          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            css={`
              &&& {
                color: #8e8e8e;
              }
            `}
            onClick={() => {
              checkEvent({ eventId });
            }}
          />
        </Header>
        <Discription>{eventTitle}</Discription>
      </Main>
    </NotificationCardStyled>
  );
};

export default NotificationCard;
