import { TableColumnType } from 'antd';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { DataNode } from 'antd/lib/tree';
import CheckboxFilterDropdown from 'components/CheckboxFilterDropdown';

interface IGetFilterCheckboxSettings {
  mode: 'tree' | 'menu';
  filters: DataNode[] | undefined;
}

const getFilterCheckboxSettings = <T,>(
  dataIndex: keyof T,
  { mode, filters }: IGetFilterCheckboxSettings
): TableColumnType<T> => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => (
      <CheckboxFilterDropdown
        type={mode}
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        dataIndex={dataIndex as string}
        visible={visible}
        filters={filters}
      />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      setTimeout(() => {
        const input = document.querySelector<HTMLInputElement>(
          '.ant-table-filter-dropdown-search-input .ant-input'
        )!;
        if (input) input.placeholder = 'Найти значение';
      }, 0);
    },
    onFilter: (value, record) => {
      return String(record[dataIndex]) === String(value);
    },
    filterMode: mode,
    filterSearch: true,
    // filters: filters,
  };
};

export default getFilterCheckboxSettings;
