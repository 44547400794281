import {
  ExclamationCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Modal,
  Space,
  Switch,
  Typography,
  Input as InputAnt,
  Checkbox,
  Input,
  Tag,
  InputRef,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Caption } from 'components/Caption';
import ModalStyled from 'components/Modal/Modal';
import OrganizationSelect from 'components/OrganizationSelect';
import Select from 'components/Select';
import { useIsFirstRender } from 'hooks/isFirstRender';
import React, {
  FC,
  MouseEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { eventApi } from 'store/api/eventApi';
import { organizationApi } from 'store/api/organizationApi';
import { IEventMapped, IEventRequest } from 'store/models/IEvent';
import { IOrg, IOrgListItem, IOrgMutation } from 'store/models/IOrg';
import getFilterCheckboxSettings from 'utils/helpers/getFilterCheckboxSettings';
import { getFilterSearchSettings } from 'utils/helpers/getFilterSearchSettings';
import { getFilterDatePickerSettings } from '../../utils/helpers/getFilterDataPickerSettings';
import { IDebtRegisterMapped } from '../../store/models/IDebtRegister';
// import { dateSort } from '../../utils/helpers/dataSort';
import { stringSort } from '../../utils/helpers/stringSort';
import moment from 'moment';
import { toDataTime } from '../../utils/helpers/toDataTime';
import treeSelect from '../Filter/components/TreeSelect';
import { useErrorMessage } from '../../hooks/useErrorMessage';

interface INotificationModalProps {
  button: FC<{ showModal: MouseEventHandler }>;
}

interface DataType {
  key: React.Key;
  name: string;
  district: string;
  email: string;
  enableEmail: boolean;
  description: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue | null>;
}

const NotificationModal: FC<INotificationModalProps> = ({ button }) => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [eventApiPrams, setEventApiPrams] = useState<IEventRequest>({
    rangeStart: 1,
    rangeEnd: 10,
  });
  const {
    data: dataEvents,
    isFetching: isFetchingEvents,
    error: errorEvents,
    refetch,
  } = eventApi.useGetEventsQuery(eventApiPrams);
  useErrorMessage(errorEvents);
  const [checkEvent] = eventApi.useCheckEventMutation();
  const { data: statusTypes } = eventApi.useGetStatusTypesQuery();
  const [checkAllEvents] = eventApi.useCheckAllEventsMutation();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      total: dataEvents?.amountOfEvents,
    },
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const [isCreatingTimePickerOpened, setIsCreatingTimePickerOpened] =
    useState(false);

  useEffect(() => {
    if (dataEvents) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: dataEvents?.amountOfEvents,
        },
      });
    }
  }, [dataEvents]);

  const toEndRange = () => {
    if (tableParams.pagination?.current && tableParams.pagination?.pageSize) {
      return tableParams.pagination.current * tableParams.pagination.pageSize;
    } else {
      return 10;
    }
  };

  const toStartRange = () => {
    const endRange = toEndRange();

    if (tableParams.pagination?.pageSize && endRange) {
      return endRange - tableParams.pagination?.pageSize + 1;
    } else {
      return 1;
    }
  };

  useEffect(() => {
    console.log(tableParams);
    if (tableParams) {
      setEventApiPrams({
        rangeStart: toStartRange(),
        rangeEnd: toEndRange(),
        isRead:
          tableParams.filters?.isRead !== null &&
          tableParams.filters?.isRead !== undefined
            ? (tableParams.filters?.isRead[0] as boolean)
            : undefined,
        messageType:
          tableParams.filters?.messageType !== null &&
          tableParams.filters?.messageType !== undefined
            ? (tableParams.filters.messageType as string[])
            : undefined,
        creatingDateStart:
          tableParams.filters?.creatingTime !== null &&
          tableParams.filters?.creatingTime !== undefined &&
          tableParams.filters?.creatingTime[0] !== ''
            ? toDataTime(tableParams.filters.creatingTime[0] as string)
            : undefined,
        creatingDateEnd:
          tableParams.filters?.creatingTime !== null &&
          tableParams.filters?.creatingTime !== undefined &&
          tableParams.filters?.creatingTime[1] !== ''
            ? toDataTime(tableParams.filters.creatingTime[1] as string)
            : undefined,
      });
    }
  }, [tableParams]);

  useEffect(() => {
    console.log(eventApiPrams);
    // refetch();
  }, [eventApiPrams]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) => {
    console.log(pagination, filters);
    setTableParams({
      ...tableParams,
      pagination,
      filters: filters,
      // ...sorter,
    });
  };

  const columns: ColumnsType<IEventMapped> = [
    {
      title: '',
      key: 'isRead',
      dataIndex: 'isRead',
      align: 'center',
      render: (_, record) => (
        <Button
          onClick={() => checkEvent({ eventId: record.eventId })}
          size="small"
          shape="circle"
          icon={record.isRead ? <CheckOutlined /> : <EyeOutlined />}
          disabled={record.isRead}
        />
      ),
      fixed: 'left',
      width: '1%',
      className: 'ant-table-row-actions-cell',
      ...getFilterCheckboxSettings<IEventMapped>('isRead', {
        mode: 'menu',
        filters: [
          { title: 'Прочитаные', key: 'true' },
          { title: 'Не прочитаные', key: 'false' },
        ],
      }),
      onFilter: (value, record) => true,
      // onFilter: (value, record) => {
      //   setEventApiPrams({ ...eventApiPrams, isRead: Boolean(value) });
      //   // return String(record['isRead']).indexOf(value as string) === 0
      //   return true;
      // },
    },
    {
      title: '',
      dataIndex: 'messageType',
      key: 'messageType',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tag
            color={
              record.messageType === 'info' ? 'processing' : record.messageType
            }
            style={{ margin: 0 }}
          >
            {record.messageType}
          </Tag>
        </div>
      ),
      width: '1%',
      className: 'ant-table-row-actions-cell',
      ...getFilterCheckboxSettings<IEventMapped>('messageType', {
        mode: 'menu',
        filters: statusTypes,
      }),
      onFilter: (value, record) => true,
    },
    {
      title: 'Дата и время',
      dataIndex: 'creatingTime',
      key: 'creatingTime',
      width: '170px',
      ...getFilterDatePickerSettings<IEventMapped>('creatingTime', {
        isDatePickerOpened: isCreatingTimePickerOpened,
        setIsDatePickerOpened: setIsCreatingTimePickerOpened,
        showTime: true,
        range: true,
      }),
      onFilter: (value, record) => true,
    },
    {
      title: 'Сообщение',
      dataIndex: 'eventTitle',
      key: 'eventTitle',
      width: 'auto',
    },
  ];

  const showModal = () => {
    console.log(dataEvents);
    refetch();
    setIsModalOpened(true);
  };

  const handleOk = () => {
    setIsModalOpened(false);
  };

  const handleCancel = () => {
    setIsModalOpened(false);
  };

  return (
    <>
      {button({ showModal })}
      {
        <ModalStyled
          style={{ top: '20px' }}
          // afterClose={() => {
          //   if (!orgData) {
          //     resetInputs()
          //   }
          //   setIsSaved(true);
          // }}
          // destroyOnClose={!orgData}
          // title={orgData ? 'Редактировать данные организации' : 'Добавить ораганизацию' }
          visible={isModalOpened}
          title={'Все уведомления'}
          onOk={handleOk}
          width={'95%'}
          onCancel={handleCancel}
          footer={[
            <Button>Обновить</Button>,
            <Button onClick={() => checkAllEvents()} type="primary">
              Отметить прочитанными все сообщения
            </Button>,
          ]}
        >
          <Table
            columns={columns}
            bordered
            size="small"
            pagination={tableParams.pagination}
            rowClassName={(record, i) =>
              record.isRead ? 'table-highlight' : ''
            }
            dataSource={dataEvents?.events}
            loading={isFetchingEvents}
            sticky
            scroll={{ x: 'auto', y: 'calc(100vh - 440px)' }}
            onChange={handleTableChange}
            css={`
              & .ant-pagination {
                margin-bottom: 0;
              }
            `}
          />
        </ModalStyled>
      }
    </>
  );
};

export default NotificationModal;
