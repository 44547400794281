import { IMainTheme } from 'interfaces/IMainTheme';

export const mainTheme: IMainTheme = {
  palette: {
    primary: {
      main: '#013365',
      light: '#038edc',
      dark: '#032446',
    },
    secondary: {
      main: '#fa1515',
      light: '',
      dark: '',
    },
    bg: '#013365',
  },
};
