import { Button, DatePicker, DatePickerProps, Space } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import moment from 'moment';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import uniqid from 'uniqid';
import { IDataFilterState } from '../utils/helpers/getFilterDataPickerSettings';

interface IDatePickerFilterDropdownProps {
  selectedKeys: string[];
  setSelectedKeys: (selectedKey: string[]) => void;
  dataIndex: string;
  isDatePickerOpened: boolean;
  setIsDatePickerOpened: (isDataPickerOpened: any) => any;
  type?: 'date' | 'week' | 'month' | 'quarter' | 'year';
  clearFilters: (() => void) | undefined;
  confirm: (param?: FilterConfirmProps) => void;
  visible: boolean;
  range?: boolean;
  showTime?: boolean;
  state?: IDataFilterState;
  onCalendarChange?: (data: [string, string]) => void;
}

const DatePickerFilterDropdown: FC<IDatePickerFilterDropdownProps> = ({
  selectedKeys,
  setSelectedKeys,
  type,
  isDatePickerOpened,
  clearFilters,
  confirm,
  visible,
  range = false,
  showTime = false,
  onCalendarChange = () => {},
  state,
}) => {
  const uniqueClassName = `ant-filter-data-picker-${uniqid()}`;
  const uniqueDropdownClassName = `ant-filter-data-picker-dropdown-${uniqid()}`;
  const [pickerWrapHieght, setPickerWrapHieght] = useState<number>(0);

  useEffect(() => {
    const picker = document.querySelector<HTMLElement>(
      `.${uniqueDropdownClassName} .ant-picker-panel-container`
    );
    const pickerWrap = document.querySelector<HTMLElement>(
      `.${uniqueDropdownClassName}`
    );
    const defaultFooter = document.querySelector<HTMLElement>(
      `.${uniqueDropdownClassName} .ant-picker-ranges`
    );

    if (picker) picker.style.boxShadow = 'none';
    if (pickerWrap) {
      pickerWrap.style.zIndex = '2000';
      pickerWrap.style.paddingBottom = '0';
      setTimeout(
        () => setPickerWrapHieght(pickerWrap.offsetHeight + 32 + 12),
        0
      );
    }
    if (defaultFooter) defaultFooter.style.display = 'none';
  });

  const imitateClickOnDefaultBtn = () => {
    const defaultOkBtn = document.querySelector<HTMLButtonElement>(
      `.${uniqueDropdownClassName} .ant-picker-ok .ant-btn`
    );
    if (defaultOkBtn) defaultOkBtn.click();
  };

  useEffect(() => {
    if (visible) {
      const input = document.querySelector<HTMLInputElement>(
        `.${uniqueClassName} input`
      );
      console.log(input);
      if (input) setTimeout(() => input.focus(), 0);
    } else if (!visible) {
      confirm();
      imitateClickOnDefaultBtn();
    }
  }, [visible]);

  const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    imitateClickOnDefaultBtn();
    // setFilterValue(selectedKeys[0]);
    // setFilteredColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    clearFilters();
    confirm();
    // imitateClickOnDefaultBtn();
    // setFilterValue('');
  };

  const handlerToday = (setSelectedKeys: (selectedKey: string[]) => void) => {
    setSelectedKeys([moment().toString()]);
    // setFilterValue(moment().toString());
    // setFilteredColumn(dataIndex);
  };

  const extraFooter = (
    <Space
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Button
          onClick={() => {
            clearFilters && handleReset(clearFilters, confirm);
          }}
          size="small"
          type="link"
          disabled={selectedKeys[0] ? false : true}
        >
          Сбросить
        </Button>
      </div>
      <div>
        {!range && (
          <Button
            onClick={() => handlerToday(setSelectedKeys)}
            size="small"
            type="link"
          >
            Сегодня
          </Button>
        )}
        <Button
          type="primary"
          onClick={() => handleSearch(confirm)}
          size="small"
        >
          ОК
        </Button>
      </div>
    </Space>
  );

  return (
    <div style={{ padding: '8px', height: pickerWrapHieght }}>
      {range ? (
        <DatePicker.RangePicker
          showTime={showTime}
          dropdownClassName={uniqueDropdownClassName}
          className={uniqueClassName}
          renderExtraFooter={() => extraFooter}
          allowClear={false}
          open={isDatePickerOpened}
          style={{ width: showTime ? '449px' : '560px' }}
          value={
            selectedKeys[0] && selectedKeys[1]
              ? [moment(selectedKeys[0]), moment(selectedKeys[1])]
              : undefined
          }
          // value={[moment('2020-10-01'), moment('2020-10-31')]}
          onCalendarChange={(data, dataString) => {
            setSelectedKeys(dataString);
            onCalendarChange(dataString);
          }}
        />
      ) : (
        <DatePicker
          allowClear={false}
          className={uniqueClassName}
          dropdownClassName={uniqueDropdownClassName}
          value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
          picker={type}
          style={{ width: '280px' }}
          open={isDatePickerOpened}
          showToday={false}
          onChange={(data, dataString) =>
            setSelectedKeys(dataString ? [dataString] : [])
          }
          renderExtraFooter={() => extraFooter}
        />
      )}
    </div>
  );
};

export default DatePickerFilterDropdown;
