import styled, { css } from 'styled-components';
import { PaperType } from 'types';

interface IPaper {
  $type?: PaperType;
}

export const PaperStyled = styled.div<IPaper>`
  background-color: white;
  padding: 16px 24px;
  border-radius: 15px;
  /* box-shadow: ${({ $type }) =>
    $type === 'default'
      ? '0px 0px 10px -2px rgba(34, 60, 80, 0.18)'
      : 'none'}; */
  box-shadow: 0px 0px 18px -13px rgba(34, 60, 80, 0.41);
  border: ${({ $type }) =>
    $type === 'stroke' ? '1px solid rgb(235, 235, 235)' : 'none'};
`;
