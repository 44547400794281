import { FC } from 'react';
import { IStatusDetail } from '../../store/models/IStatusDetail';
import { List, Typography } from 'antd';
import moment from 'moment';
import RegisterStatus from '../../pages/payment-registers/configuration-tab/configuration-registers/RegisterStatus';

interface IStatusDetailItemProps {
  data: IStatusDetail;
}

const StatusDetailItem: FC<IStatusDetailItemProps> = ({ data }) => {
  return (
    <List.Item style={{ display: 'flex', justifyContent: 'space-between' }}>
      <RegisterStatus status={data.text} />
      <Typography.Text type={'secondary'}>
        {moment(data.creatingDate).format('DD.MM.YYYY, HH:mm:ss')}
      </Typography.Text>
    </List.Item>
  );
};

export default StatusDetailItem;
