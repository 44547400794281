import moment from 'moment';
import { IDebtRegister, IDebtRegisterMapped } from 'store/models/IDebtRegister';
import { API } from 'utils/endpointsConst';
import { baseProtectedApi } from './baseApi';
import {
  IPaymentRegister,
  IPaymentRegisterMapped,
} from '../models/IPaymentRegister';
import { IPayments, IPaymentsMapped, IPaymentsReq } from '../models/IPayments';
import React from 'react';
import { IStatusDetail } from '../models/IStatusDetail';

export const paymentRegisterApi = baseProtectedApi.injectEndpoints({
  endpoints: (build) => ({
    getRegisters: build.query<
      IPaymentRegisterMapped[],
      { Start: string; Finish: string }
    >({
      query: (params) => ({
        url: API.paymentRegister.getRegistries,
        method: 'GET',
        params,
      }),
      providesTags: ['PaymentRegisters'],
      transformResponse: (response: IPaymentRegister[]) => {
        return response.map((element) => {
          return {
            ...element,
            creatingDate: moment(element.creatingDate).format(
              'DD.MM.YYYY, HH:mm:ss'
            ),
            outputFileDate: moment(element.outputFileDate).format('DD.MM.YYYY'),
            key: element.paymentRegistryId,
            statusType: element.statusText,
            paymentOrders: element.paymentOrders.map((paymentOrder) => ({
              ...paymentOrder,
              key: paymentOrder.paymentOrderId,
              formationDate: moment(paymentOrder.formationDate).format(
                'DD.MM.YYYY'
              ),
            })),
          };
        });
      },
    }),
    getPayments: build.query<IPaymentsMapped[], IPaymentsReq>({
      query: (params) => ({
        url: API.paymentRegister.getPayments,
        method: 'GET',
        params,
      }),
      transformResponse: (response: IPayments[]) =>
        response.map((paymemnt) => ({
          ...paymemnt,
          key: String(paymemnt.paymentId),
        })),
    }),
    getStatusDetails: build.query<IStatusDetail[], { id: string }>({
      query: (params) => ({
        url: API.paymentRegister.getStatusDetails,
        method: 'GET',
        params,
      }),
      // transformResponse: (response: IPayments[]) =>
      //   response.map((paymemnt) => ({
      //     ...paymemnt,
      //     key: String(paymemnt.paymentId),
      //   })),
    }),
  }),
});
