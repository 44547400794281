import moment, { Moment } from 'moment';

// export const dateSort = (
//   a: string,
//   b: string,
//   format: string,
//   sortOrder: 'ascend' | 'descend'
// ): number => {
//   const dateA = moment(a);
//   const dateB = moment(b);
//   const dateAFormatted = dateA.format(format);
//   const dateBFormatted = dateB.format(format);
//
//   if (sortOrder === 'ascend') {
//     return dateAFormatted.localeCompare(dateBFormatted);
//   } else if (sortOrder === 'descend') {
//     return dateBFormatted.localeCompare(dateAFormatted);
//   }
//   return 0;
// };

export function dateSorter(a: Moment, b: Moment) {
  if (!a.isValid() || !b.isValid()) {
    console.error(
      `dateSorter: Error occurred while validating input params: a.isValid = ${a.isValid()}, b.isValid = ${b.isValid()}`
    );
    return 0;
  }
  if (a.utc().isBefore(b)) {
    console.log(`${a} is before ${b}`);
    return -1;
  }

  if (a.utc().isAfter(b)) {
    console.log(`${a} is after ${b}`);
    return 1;
  }

  return 0;
}
