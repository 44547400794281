import styled from 'styled-components';
import { PaperType } from 'types';

interface IHeader {
  $type?: PaperType;
}

export const Header = styled.div<IHeader>`
  &&& {
    border-bottom: ${({ $type }) =>
      $type === 'stroke' ? '1px solid rgb(235, 235, 235)' : 'none'};
    margin-right: -24px;
    margin-left: -24px;
    margin-bottom: 16px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 16px;
    & * {
      margin: 0;
      color: #666;
    }
  }
`;
