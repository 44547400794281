import React, { FC } from 'react';
import { Caption } from '../../Caption';
import { DatePicker } from 'antd';
import { ReportingParameterProps } from './types';

type IDateParamProps = Omit<
  React.ComponentProps<typeof DatePicker>,
  'onChange'
> &
  ReportingParameterProps;

export const DateParam: FC<IDateParamProps> = ({
  paramData,
  onChange = () => {},
  ...props
}) => {
  return (
    <Caption text={paramData?.name || ''}>
      <DatePicker
        {...props}
        onChange={(value, stringValues) => {
          onChange([stringValues]);
        }}
      />
    </Caption>
  );
};
