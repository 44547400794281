import { FC } from 'react';
import { Typography } from 'antd';
import { toRubles } from '../../../utils/helpers/toRubles';

interface ITableFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  registerSum: number;
  paymentOrdersSum: number;
  showPOSum: boolean;
}

const TableFooter: FC<ITableFooterProps> = ({
  registerSum,
  paymentOrdersSum,
  showPOSum,
}) => {
  return (
    <div>
      <Typography style={{ fontWeight: 500 }}>
        Сумма реестров: {toRubles(registerSum)}
      </Typography>
      {showPOSum && (
        <Typography style={{ fontWeight: 500 }}>
          Сумма ПП: {toRubles(paymentOrdersSum)}
        </Typography>
      )}
    </div>
  );
};

export default TableFooter;
