import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import { IPaymentOrderMapped } from 'store/models/IPaymentRegister';
import { toRubles } from '../../../../utils/helpers/toRubles';

interface IConfigurationFileRegistersProps {
  paymentOrders: IPaymentOrderMapped[];
  registerDate: string;
}

const ConfigurationFileRegisters: FC<IConfigurationFileRegistersProps> = ({
  paymentOrders,
  registerDate,
}) => {
  const columns: ColumnsType<IPaymentOrderMapped> = [
    {
      title: '№',
      dataIndex: 'poNumber',
      key: 'poNumber',
      width: '90px',
    },
    {
      title: 'Дата',
      dataIndex: 'formationDate',
      key: 'formationDate',
      width: '100px',
    },
    {
      title: 'Сумма',
      dataIndex: 'poAmount',
      key: 'poAmount',
      width: '130px',
      render: (_, record) => toRubles(record.poAmount),
    },
    {
      title: `в т. ч. за ${registerDate}`,
      dataIndex: 'amount',
      key: 'amount',
      width: '130px',
      render: (_, record) => toRubles(record.amount),
    },
    {
      title: 'Назначение',
      dataIndex: 'appointment',
      key: 'appointment',
      width: 'auto',
    },
  ];

  return (
    <>
      <Typography.Title level={5} style={{ marginBottom: '16px' }}>
        Платежные поручения
      </Typography.Title>
      <Table
        pagination={{
          showSizeChanger: true,
        }}
        sticky
        scroll={{ x: 'auto', y: 'auto' }}
        size="small"
        columns={columns}
        bordered
        summary={(data) => (
          <tr>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'end' }}>
              <Typography.Text strong>Итого:</Typography.Text>
            </td>
            <td>
              <Typography.Text strong>
                {toRubles(data.reduce((acc, record) => acc + record.amount, 0))}
              </Typography.Text>
            </td>
            <td></td>
          </tr>
        )}
        dataSource={paymentOrders}
        css={`
          & .ant-pagination {
            margin-bottom: 0;
          }
        `}
      />
    </>
  );
};

export default ConfigurationFileRegisters;
