import {
  Button,
  Checkbox,
  DatePicker,
  InputRef,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import Paper from 'components/Paper';
import React, {
  FC,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ColumnsType } from 'antd/lib/table';
import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Filter from 'components/Filter';
import ButtonCollapse from 'components/ButtonCollapse';
import { debtRegisterApi } from 'store/api/debtRegisterApi';
import { IDebtRegisterMapped } from 'store/models/IDebtRegister';
import DownloadButton from './DownloadButton';
import { getFilterSearchSettings } from 'utils/helpers/getFilterSearchSettings';
import { getFilterDatePickerSettings } from 'utils/helpers/getFilterDataPickerSettings';
import getFilterCheckboxSettings from 'utils/helpers/getFilterCheckboxSettings';
import { organizationApi } from 'store/api/organizationApi';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { DataNode } from 'antd/lib/tree';
import { stringSort } from '../../utils/helpers/stringSort';
import ConfigurationRegisters from '../payment-registers/configuration-tab/configuration-registers';
import ConfigurationFileModal from './ConfigurationRegisterModal';
import downloadFile from '../../utils/helpers/downloadFile';
import { apiAddressConst } from '../../utils/apiAddressConst';
import { API } from '../../utils/endpointsConst';
import { IPaymentRegisterMapped } from '../../store/models/IPaymentRegister';
import moment from 'moment';
import { dateSorter } from '../../utils/helpers/dataSort';
import { useErrorMessage } from '../../hooks/useErrorMessage';

// const ogrFilterMapper = (initArray: IDebtRegisterMapped[]) => {
//   initArray.forEach((elem) => {
//     elem.
//   })
// }

interface IDebtRegistersContainerProps {
  name: string;
}

const DebtRegistersContainer: FC<IDebtRegistersContainerProps> = ({ name }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const dateFilterInit: [string, string] = [
    moment().month(moment().month()).startOf('month').format('YYYY-MM-DD'),
    moment().month(moment().month()).endOf('month').format('YYYY-MM-DD'),
  ];
  const [dateFilter, setDateFilter] =
    useState<[string, string]>(dateFilterInit);
  const {
    data: dataDebtRegisters,
    isFetching,
    error,
  } = debtRegisterApi.useGetAllQuery({
    Start: dateFilter[0],
    Finish: dateFilter[1],
  });
  useErrorMessage(error);

  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);
  const [isBillingPeriodPickerOpened, setIsBillingPeriodPickerOpened] =
    useState(false);
  const [isCreatingTimePickerOpened, setIsCreatingTimePickerOpened] =
    useState(false);
  const [dataRangeFilter, setDataRangeFilter] = useState<React.Key[]>([]);

  const {
    data: orgData,
    isFetching: isFetchingOrg,
    error: orgError,
  } = organizationApi.useGetOrganizationsQuery();
  useErrorMessage(orgError);

  // const orgDataMapped = useMemo(
  //   () =>
  //     orgData?.map(
  //       (district) =>
  //         ({
  //           title: district.name,
  //           key: district.id,
  //           children: district.organizationList.map((org) => ({
  //             title: org.name,
  //             key: org.id,
  //           })),
  //         } as DataNode)
  //     ),
  //   [orgData]
  // );

  const handleDateRangeOnChange = (dateRange: [string, string]) => {
    const [start, end] = dateRange;
    if (start === '' && end === '') {
      setDateFilter(dateFilterInit);
    } else {
      setDateFilter(dateRange);
    }
  };

  const orgDataMapped = useMemo(() => {
    const filterItems: DataNode[] = [];
    if (dataDebtRegisters) {
      dataDebtRegisters.forEach((record) => {
        let hasAlreadyBeenThere = false;
        filterItems.forEach((district) => {
          if (district.children) {
            district.children.sort((a, b) =>
              stringSort(a.title as string, b.title as string, 'ascend')
            );
          }
          if (
            !district.children
              ? true
              : district.children?.findIndex(
                  (org) => org.key === record.orgId
                ) !== -1
          ) {
            hasAlreadyBeenThere = true;
          }
        });
        if (hasAlreadyBeenThere) return undefined;

        const filterItemIndex = filterItems.findIndex(
          (item) => item.title === record.orgDistrict
        );
        if (filterItemIndex === -1) {
          filterItems.push({
            title: record.orgDistrict,
            key: record.key,
            children: [
              {
                title: record.orgName,
                key: record.orgId,
              },
            ],
          });
        } else {
          filterItems[filterItemIndex].children?.push({
            title: record.orgName,
            key: record.orgId,
          });
        }
      });
    }
    filterItems.sort((a, b) =>
      stringSort(a.title as string, b.title as string, 'ascend')
    );
    return filterItems;
  }, [dataDebtRegisters]);

  const columns: ColumnsType<IDebtRegisterMapped> = [
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      render: (_, record) => (
        <Button
          disabled={record.files.length === 0}
          type="default"
          shape="circle"
          size="small"
          icon={<DownloadOutlined />}
          onClick={() =>
            downloadFile(
              `${apiAddressConst}${API.files.downloadMany}?ids=` +
                record.files.map((file) => file.id).join('&ids='),
              record.arhiveName
            )
          }
        />
      ),
      fixed: 'left',
      width: '1%',
      className: 'ant-table-row-actions-cell',
    },
    {
      title: 'Дата загрузки',
      dataIndex: 'creatingDate',
      key: 'creatingDate',
      width: '170px',
      sorter: (a, b) =>
        dateSorter(
          moment(a.creatingDate, 'DD.MM.YYYY, HH:mm:ss'),
          moment(b.creatingDate, 'DD.MM.YYYY, HH:mm:ss')
        ),
      // ...getFilterDatePickerSettings<IDebtRegisterMapped>('creatingDate', {
      //   isDatePickerOpened: isCreatingTimePickerOpened,
      //   setIsDatePickerOpened: setIsCreatingTimePickerOpened,
      //   range: true,
      //   showTime: true,
      //   state: {
      //     get: dataRangeFilter,
      //     set: setDataRangeFilter,
      //   },
      // }),
    },
    {
      title: 'Период',
      dataIndex: 'billingPeriod',
      key: 'billingPeriod',
      width: '140px',
      sorter: (a, b) =>
        dateSorter(
          moment(a.billingPeriod, 'MMMM YYYY'),
          moment(b.billingPeriod, 'MMMM YYYY')
        ),
      ...getFilterDatePickerSettings<IDebtRegisterMapped>('billingPeriod', {
        isDatePickerOpened: isBillingPeriodPickerOpened,
        setIsDatePickerOpened: setIsBillingPeriodPickerOpened,
        type: 'month',
      }),
    },
    {
      title: 'Пользователь',
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a, b) => stringSort(a.userName, b.userName, 'ascend'),
      width: '160px',
      ...getFilterSearchSettings<IDebtRegisterMapped>('userName', {
        searchInput: searchInput as MutableRefObject<InputRef>,
        searchText,
        searchedColumn,
        setSearchedColumn,
        setSearchText,
      }),
    },
    {
      title: 'ФИО',
      dataIndex: 'fio',
      key: 'fio',
      sorter: (a, b) => stringSort(a.fio, b.fio, 'ascend'),
      width: '140px',
      ...getFilterSearchSettings<IDebtRegisterMapped>('fio', {
        searchInput: searchInput as MutableRefObject<InputRef>,
        searchText,
        searchedColumn,
        setSearchedColumn,
        setSearchText,
      }),
    },
    {
      title: 'Владелец',
      dataIndex: 'orgId',
      key: 'orgId',
      width: '200px',
      render: (_, record) => record.orgName,
      sorter: (a, b) => stringSort(a.orgName, b.orgName, 'ascend'),
      ...getFilterCheckboxSettings<IDebtRegisterMapped>('orgId', {
        mode: 'tree',
        filters: orgDataMapped,
      }),
    },
    {
      title: 'Комментарий',
      dataIndex: 'desc',
      key: 'desc',
      sorter: (a, b) => stringSort(a.desc, b.desc, 'ascend'),
      width: '200px',
      ...getFilterSearchSettings<IDebtRegisterMapped>('desc', {
        searchInput: searchInput as MutableRefObject<InputRef>,
        searchText,
        searchedColumn,
        setSearchedColumn,
        setSearchText,
      }),
    },
  ];

  return (
    <Paper
      type="stroke"
      header={<Typography.Title level={5}>{name}</Typography.Title>}
    >
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '16px',
        }}
      >
        <DatePicker.RangePicker
          style={{
            width: 400,
          }}
          allowEmpty={[false, false]}
          value={[
            moment(dateFilter[0], 'YYYY-MM-DD'),
            moment(dateFilter[1], 'YYYY-MM-DD'),
          ]}
          onChange={(_, dateRange) => handleDateRangeOnChange(dateRange)}
        />
        <ConfigurationFileModal
          button={({ showModal }) => (
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={showModal}
            >
              Добавить
            </Button>
          )}
        />
      </div>
      <Table
        pagination={{
          showSizeChanger: true,
        }}
        size="small"
        columns={columns}
        bordered
        dataSource={dataDebtRegisters}
        loading={isFetching}
        sticky
        scroll={{ x: 'max-content', y: 'auto' }}
        css={`
          & .ant-pagination {
            margin-bottom: 0;
          }
        `}
      />
    </Paper>
  );
};

export default DebtRegistersContainer;
