import { Menu } from 'antd';
import styled from 'styled-components';

interface IItem {
  isSelected: boolean;
}

export const Item = styled(Menu.Item)<IItem>`
  &&& {
    display: flex;
    align-items: center;
    line-height: initial;
    margin: 10px 0;
    padding: 8px 11px;
    height: initial;
    border-radius: 8px;
    background: ${({ isSelected }) => (isSelected ? 'white !important' : null)};
    padding: ${({ isSelected }) => (isSelected ? '8px 18px' : null)};

    &:hover {
      background: ${({ isSelected }) =>
        isSelected ? 'white !important' : '#032446 !important'};
      padding: 8px 18px;
    }
  ;

    & > * {
      background-color: ${({ isSelected }) =>
        isSelected ? '$transperant' : null};
      transition: none;
      margin-left: 0;

      &:first-child {
        margin-right: 10px;
      }
    }
  ;

    & * {
      color: ${({ isSelected, theme }) => (isSelected ? '#032446' : null)};
      font-weight: ${({ isSelected, theme }) => (isSelected ? 700 : null)};
    }
`;
