import React, { FC } from 'react';
import { Input, Select } from 'antd';
import { ReportingParameterProps } from './types';
import { Caption } from '../../Caption';

type IInputParamProps = Omit<React.ComponentProps<typeof Input>, 'onChange'> &
  ReportingParameterProps;

export const InputParam: FC<IInputParamProps> = ({
  paramData,
  onChange = () => {},
  ...props
}) => {
  return (
    <Caption text={paramData?.name || ''}>
      <Input
        {...props}
        onChange={(e) => {
          onChange([e.target.value]);
        }}
      />
    </Caption>
  );
};
