import { createApi } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import {
  IEvent,
  IEventMapped,
  IEventRequest,
  IEventRes,
  IEventResMapped,
} from 'store/models/IEvent';
import { NotificationType } from 'types';
import { API } from 'utils/endpointsConst';
import { baseProtectedApi } from './baseApi';

export const eventApi = baseProtectedApi.injectEndpoints({
  endpoints: (build) => ({
    getUnreadEvents: build.query<IEvent[], { range: number }>({
      query: (params) => ({
        url: API.events.allUnreadEvents,
        params,
      }),
      providesTags: ['Events'],
    }),
    getEvents: build.query<IEventResMapped, IEventRequest>({
      query: (params) => ({
        url: API.events.events,
        params,
      }),
      providesTags: ['Events'],
      transformResponse: (response: IEventRes, meta, arg) => {
        const mappedEvents = response.events.map((event) => ({
          ...event,
          creatingTime: moment(event.creatingTime).format(
            'DD.MM.YYYY, HH:mm:ss'
          ),
          messageType: event.messageType.toLowerCase() as NotificationType,
          key: event.eventId,
        }));
        return { ...response, events: mappedEvents };
      },
    }),
    checkEvent: build.mutation<void, { eventId: string }>({
      query: (body) => {
        return {
          url: API.events.checkEvent,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Events'],
    }),
    checkAllEvents: build.mutation<void, void>({
      query: (body) => {
        return {
          url: API.events.checkAllEvents,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['Events'],
    }),
    getStatusTypes: build.query<{ title: string; key: string }[], void>({
      query: () => {
        return {
          url: API.events.statusTypes,
          method: 'GET',
        };
      },
      transformResponse: (response: string[], meta, arg) =>
        response.map((elem, i) => ({ title: elem, key: elem })),
    }),
  }),
});
