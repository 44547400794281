import { FC } from 'react';
import styled from 'styled-components';
import { Avatar, Typography } from 'antd';
import UserInfoRow from './UserInfoRow';
import { UserOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../hooks/redux';
import { userApi } from '../../store/api/userApi';

interface IUserPopupProps {}

const Wrap = styled.div`
  width: 300px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 25px;
  padding-top: 30px;
`;

const Body = styled.div`
  padding: 10px 25px;
`;

const UserName = styled(Typography)`
  font-style: italic;
  font-size: 12px;
  color: #a0a0a0;
  margin-top: -3px;
`;

const Fio = styled(Typography)``;

const Footer = styled.div``;

const userData = {
  'E-mail': 'danileph@bk.ru',
  Роль: 'admin',
  Организация: 'РРЦ',
};

const objToJsx = (
  obj: { [key: string]: any },
  callback: (key: string, value: any) => any
): any[] => {
  const mappedObj = [];
  for (let objKey in obj) {
    mappedObj.push(callback(objKey, obj[objKey]));
  }
  return mappedObj;
};

const UserPopup: FC<IUserPopupProps> = () => {
  const token = useAppSelector((state) => state.auth.token);
  const { data: userMetaData } = userApi.useGetUserQuery();

  return (
    <Wrap>
      <Header>
        <Avatar
          style={{ marginBottom: '10px' }}
          size={64}
          icon={<UserOutlined />}
        />
        <Fio>{userMetaData?.fio ? userMetaData?.fio : 'ФИО не указано'}</Fio>
        <UserName>{userMetaData?.userName}</UserName>
      </Header>
      <Body>
        <UserInfoRow
          name={'E-mail'}
          value={userMetaData?.email ? userMetaData?.email : 'не указн'}
        />
        <UserInfoRow
          name={'Организация'}
          value={
            userMetaData?.orgName
              ? userMetaData.orgName
              : userMetaData?.orgId
              ? userMetaData?.orgId
              : ''
          }
        />
        <UserInfoRow
          name={'Телефон'}
          value={
            userMetaData?.phoneNumber ? userMetaData?.phoneNumber : 'не указан'
          }
        />
      </Body>
    </Wrap>
  );
};

export default UserPopup;
