import { API } from 'utils/endpointsConst';
import { baseProtectedApi } from './baseApi';
import { IApiVersion } from '../models/IApiVersion';

export const appSettingsApi = baseProtectedApi.injectEndpoints({
  endpoints: (build) => ({
    getVersion: build.query<{ version: string }, void>({
      query: (params) => ({
        url: API.appSettings.version,
      }),
    }),
    getServicePrivacyPolicy: build.query<{ text: string }, void>({
      query: () => ({
        url: API.appSettings.servicePrivacyPolicy,
      }),
    }),
    getServiceUsageRules: build.query<{ text: string }, void>({
      query: () => ({
        url: API.appSettings.serviceUsageRules,
      }),
    }),
  }),
});
