import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuth, IToken } from 'store/models/IAuth';
import { default as jwtDecode } from 'jwt-decode';
import { ILoginResponse } from 'store/models/ILoginResponse';
import {
  getTokenFromCookies,
  removeTokenFromCookies,
  setTokenIntoCookies,
} from 'utils/helpers/cookies';
import Cookies from 'universal-cookie';

const initialState: IAuth = {
  token: {},
  expiration: '',
};

const cookies = new Cookies();

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<ILoginResponse>) => {
      const { expiration, token } = action.payload;
      const decodedToken: IToken = jwtDecode(token);
      setTokenIntoCookies(token);
      state.expiration = expiration;
      state.token = {
        ...decodedToken,
        Roles: ['User'],
      };
    },
    deleteCredentials: (state) => {
      state.token = {};
      state.expiration = '';
      removeTokenFromCookies();
    },
    initCredentials: (state) => {
      const token = getTokenFromCookies();
      if (token) {
        const decodedToken: IToken = jwtDecode(token);
        state.token = {
          ...decodedToken,
          Roles: ['User'],
        };
        state.expiration = '';
      }
    },
  },
});

export default authSlice;
export const authActions = authSlice.actions;
