import { ChangeEvent, FC, HTMLAttributes, useEffect, useState } from 'react';
import { Input, Typography } from 'antd';
import { Caption } from '../Caption';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { localStorageConst } from '../../utils/localStorageConst';
import { apiAddressConst } from '../../utils/apiAddressConst';

interface IInputTestApiProps extends HTMLAttributes<HTMLElement> {}

const InputTestApi: FC<IInputTestApiProps> = (props) => {
  const [apiAddress, setApiAddress] = useState(apiAddressConst);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setApiAddress(e.target.value);
    localStorage.setItem(localStorageConst.testApiAddress, e.target.value);
  };

  return (
    <div
      style={{ display: 'flex', alignItems: 'baseline', gap: '10px' }}
      {...props}
    >
      <Typography style={{ width: 250 }}>IP-address for test API: </Typography>
      <Input
        size="small"
        placeholder="http://192.168.10.200:86"
        value={apiAddress}
        onChange={(e) => handleInputChange(e)}
        style={{ color: 'black' }}
      />
    </div>
  );
};

export default InputTestApi;
