import { IAuth } from 'store/models/IAuth';
import { ILoginRequest } from 'store/models/ILoginRequest';
import { ILoginResponse } from 'store/models/ILoginResponse';
import { IUser } from 'store/models/IUser';
import { API } from 'utils/endpointsConst';
import { basePublicApi } from './baseApi';

export const authApi = basePublicApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<ILoginResponse, ILoginRequest>({
      query: (credentials) => ({
        url: API.auth.login,
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    loginOut: build.mutation<unknown, string>({
      query: (token) => ({
        url: API.auth.loginOut,
        method: 'POST',
        body: { token },
      }),
    }),
    register: build.mutation<unknown, IUser>({
      query: (userData) => ({
        url: API.auth.register,
        method: 'POST',
        body: { ...userData },
      }),
    }),
  }),
});
