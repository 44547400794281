import styled from 'styled-components';
import { Layout as AntLayout } from 'antd';

export const Footer = styled(AntLayout.Footer)`
  &&& {
    line-height: initial;
    padding: 8px 40px;
    background-color: white;
    border-top: 1px solid rgb(235, 235, 235);
    position: relative;
    color: #c6c6c6;
    z-index: 100;
    box-shadow: 0px 6px 25px -10px rgba(34, 60, 80, 0.41);
    //border-radius: 0 15px 15px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    position: sticky;
    bottom: 0;
  }
`;
