import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { PublicLayoutStyled } from './PublicLayoutStyled';

interface IPublicLayoutContainer {
  children?: JSX.Element;
}

const PublicLayoutContainer: FC<IPublicLayoutContainer> = ({ children }) => {
  return (
    <PublicLayoutStyled>
      <Outlet />
    </PublicLayoutStyled>
  );
};

export default PublicLayoutContainer;
