import { notification } from 'antd';
import { RealEventType } from 'store/models/ISignalR';
import { NotificationOpt, NotificationType } from 'types';

export const openStatusNotification = (event: RealEventType) => {
  notification[event.messageType.toLowerCase() as NotificationType]({
    message: 'Какое-то время',
    description: event.eventTitle,
  });
};
