export const API = {
  auth: {
    register: '/api/v1/auth/register',
    login: '/api/v1/auth/login',
    loginOut: '/api/v1/auth/login-out',
  },
  debtRegister: {
    download: '/api/v1/debt-register/download',
    upload: '/api/v1/debt-register/extended-upload',
    getAll: '/api/v1/debt-register',
    id: '',
  },
  organization: {
    all: '/api/v1/organization/orgs-list',
    located: '/api/v1/organization',
    getCurrent: '/api/v1/organization/get-current-org',
    set: '/api/v1/organization/org-settings',
    updateCurrent: '/api/v1/organization/current-org-settings',
  },
  paymentRegister: {
    setMetaMrt: '/api/v1/payment-registry/set-report',
    generatePeriods: '/api/v1/file-settings/periodicity',
    formats: '/api/v1/file-settings/formats',
    encodings: '/api/v1/file-settings/codding-pages',
    getMrt: '/api/v1/payment-registry/reports',
    create: '/api/v1/payment-registry/create',
    send: '/api/v1/payment-registry/send',
    stop: '/api/v1/payment-registry/stop',
    createAndSend: '/api/v1/payment-registry/create-and-send',
    getRegistries: '/api/v1/payment-registry/date-limited-client-pay-reg',
    getPayments: '/api/v1/payment-registry/payments',
    addRegistry: '/api/v1/regestry-settings',
    updateRegistry: '/api/v1/regestry-settings',
    deleteRegistry: '/api/v1/regestry-settings',
    getRegistryFiles: '/api/v1/file-settings',
    addRegistryFile: '/api/v1/file-settings',
    updateRegistyFile: '/api/v1/file-settings',
    deleteRegistyFile: '/api/v1/file-settings',
    getStatusDetails: '/api/v1/payment-registry/status-statistics',
  },
  signalR: {
    sendMessage: '/api/v1/sendMessage',
  },
  events: {
    allUnreadEvents: '/api/v1/event/all-unread-events',
    checkEvent: '/api/v1/event/check-event',
    checkAllEvents: '/api/v1/event/check-all-events',
    events: '/api/v1/event',
    statusTypes: '/api/v1/event/status-types',
  },
  users: {
    getUsers: '/api/v1/user-controller',
    createUser: '/api/v1/user-controller/create',
    updateUser: '/api/v1/user-controller/current',
    generatePassword: '/api/v1/user-controller/password/generate',
    getUser: '/api/v1/user-controller/get-by-current-id',
  },
  rolse: {
    getRoles: '/api/v1/role-controller',
  },
  files: {
    upload: '/api/v1/File/upload',
    download: '/api/v1/File/download',
    downloadFree: '/api/v1/File/download_free',
    downloadMany: '/api/v1/File/download_many',
    paymentRegisterDownload: '/api/v1/payment-registry/download',
  },
  appSettings: {
    version: '/api/v1/app-setting/version',
    servicePrivacyPolicy: '/api/v1/app-setting/rules-of-use',
    serviceUsageRules: '/api/v1/app-setting/service-privacy-policy',
  },
  reporting: {
    getParams: '/api/v1/report/parameters',
    getReportParams: '/api/v1/report/report-parameters',
    getOrgReports: '/api/v1/report/org-reports',
    getReport: '/api/v1/report',
    createReport: '/api/v1/report',
    deleteReport: '/api/v1/report',
    updateReport: '/api/v1/report',
    renderReport: '/api/v1/report/render-rep',
    getParamOptions: '/api/v1/report/values',
    getReportStories: '/api/v1/user-files',
    deleteReportStory: '/api/v1/user-files',
  },
};
