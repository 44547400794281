import { FC } from 'react';
import { PaperType } from 'types';
import { Header } from './components/Header';
import { PaperStyled } from './PaperStyled';

interface IPaperContainerProps {
  children: React.ReactNode;
  type?: PaperType;
  className?: string;
  header?: React.ReactNode;
}

const PaperContainer: FC<IPaperContainerProps> = ({
  type = 'default',
  children,
  className,
  header,
}) => {
  return (
    <PaperStyled className={className} $type={type}>
      {header && <Header $type={type}>{header}</Header>}
      {children}
    </PaperStyled>
  );
};

export default PaperContainer;
