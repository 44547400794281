import { FileMarkdownOutlined, InboxOutlined } from '@ant-design/icons';
import { Tooltip, UploadProps, message, Upload } from 'antd';
import { DraggerProps } from 'antd/lib/upload';
import React, { Dispatch, SetStateAction, useId, useState } from 'react';
import UploadedFile from './components/UploadedFile';
import { getTokenFromCookies } from '../../utils/helpers/cookies';
import uniqid from 'uniqid';
import { Guid } from 'guid-typescript';

const { Dragger } = Upload;

export interface IFileState {
  name: string;
  id?: string | Guid;
  isLoading: boolean;
}

interface IDragAndDropContainer extends DraggerProps {
  action?: string;
  files: {
    get: IFileState[] | [];
    set: Dispatch<SetStateAction<IFileState[] | []>>;
  };
  showInitFile?: boolean;
}

const DragAndDropContainer: React.FC<IDragAndDropContainer> = ({
  action,
  files,
  showInitFile = false,
  ...other
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(!!showInitFile);
  const [isError, setIsError] = useState(false);
  // const [fileName, setFileName] = useState<string | undefined>(file.name.);
  const [previewNames, setPreviewNames] = useState<string[] | []>([]);
  // const [fileId, setFileId] = useState<string | undefined>(file?.id);

  const statesReboot = () => {
    setIsDone(false);
    setIsError(false);
  };

  return (
    <>
      <Dragger
        name="file"
        multiple={true}
        headers={{
          authorization: `Bearer ${getTokenFromCookies()}`,
        }}
        action={action}
        showUploadList={false}
        onChange={(info) => {
          // console.log('uploading has changed');
          console.log(info);
          const { status } = info.file;

          if (info.file.status === 'uploading' && info.file.percent === 0) {
            console.log(`${info.file.name}-${info.file.uid} is uploading`);
            files.set((prevState) => [
              ...prevState,
              {
                name: info.file.name,
                isLoading: true,
                id: info.file.uid,
              },
            ]);
          } else if (info.file.status === 'done') {
            console.log(`${info.file.name}-${info.file.uid} has been uploaded`);
            files.set((prevState) =>
              prevState.map((file) =>
                file.id === info.file.uid
                  ? {
                      name: info.file.response.normalizeName,
                      id: info.file.response.id,
                      isLoading: false,
                    }
                  : file
              )
            );
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (info.file.status === 'error') {
            files.set((prevState) =>
              prevState.filter((file) => {
                if (file.id !== info.file.uid) {
                  return true;
                } else message.error(`${info.file.name} file upload failed.`);
              })
            );
          }

          // if (status === 'uploading' && info.file.percent === 0) {
          //   // setPreviewNames([...previewNames, info.file.name]);
          //   files.set([
          //     ...files.get,
          //     {
          //       name: info.file.name,
          //       isLoading: true,
          //       id: previewFileId,
          //     },
          //   ]);
          // }
          // if (status === 'done') {
          //   statesReboot();
          //   setIsDone(true);
          //   // setFileName(info.file.name);
          //   // files.set([
          //   //   ...files.get,
          //   //   {
          //   //     name: info.file.response.normalizeName,
          //   //     id: info.file.response.id,
          //   //     isLoading: false,
          //   //   },
          //   // ]);
          //   // files.set(files.get.map(file => file.id === ))
          //   setPreviewNames([
          //     ...previewNames.filter((name) => name !== info.file.name),
          //   ]);
          //   message.success(`${info.file.name} file uploaded successfully.`);
          // } else if (status === 'error') {
          //   setIsLoading(false);
          //   setIsError(true);
          //   message.error(`${info.file.name} file upload failed.`);
          // }
          // }
        }}
        onDrop={(e) => {}}
        {...other}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Нажмите или перетащите файл для загрузки
        </p>
        <p className="ant-upload-hint">Вы можете закрепить несколько файлов</p>
      </Dragger>
      {files.get.map((file) => (
        <UploadedFile
          key={file.id as string}
          fileId={file.id as string}
          fileName={file.name || ''}
          fileIcon={<FileMarkdownOutlined />}
          isLoading={file.isLoading}
          deleteFile={() =>
            files.set(files.get.filter((fileState) => fileState.id !== file.id))
          }
        />
      ))}
    </>
  );
};

export default DragAndDropContainer;
