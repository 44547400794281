import { Typography } from 'antd';
import React, { FC } from 'react';

interface ICaptionProps extends React.HTMLAttributes<HTMLElement> {
  text: string;
}

const Caption: FC<ICaptionProps> = ({ children, text, ...other }) => {
  return (
    <div {...other}>
      {text !== '' && (
        <Typography.Paragraph
          style={{ marginBottom: '8px', lineHeight: '12px' }}
        >
          {text}
        </Typography.Paragraph>
      )}
      {children}
    </div>
  );
};

export default Caption;
