import React, {
  FC,
  MouseEventHandler,
  MutableRefObject,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IPayments, IPaymentsMapped } from 'store/models/IPayments';
import ModalStyled from '../../../../components/Modal/Modal';
import { Button, InputRef, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { IEventMapped } from '../../../../store/models/IEvent';
import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import getFilterCheckboxSettings from '../../../../utils/helpers/getFilterCheckboxSettings';
import { getFilterDatePickerSettings } from '../../../../utils/helpers/getFilterDataPickerSettings';
import { paymentRegisterApi } from '../../../../store/api/paymentRegisterApi';
import { Guid } from 'guid-typescript';
import { toRubles } from '../../../../utils/helpers/toRubles';
import { getFilterSearchSettings } from '../../../../utils/helpers/getFilterSearchSettings';
import { IPaymentRegisterMapped } from '../../../../store/models/IPaymentRegister';
import { DataNode } from 'antd/lib/tree';
import { stringSort } from '../../../../utils/helpers/stringSort';
import { numberSorter } from '../../../../utils/helpers/numberSorter';

interface IPaymentsModalProps {
  paymentRegistryId: Guid;
  registerDate: string;
  button: FC<{ showModal: MouseEventHandler }>;
}

const PaymentsModal: FC<IPaymentsModalProps> = ({
  paymentRegistryId,
  registerDate,
  button,
}) => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const { data: payments, isFetching: ArePaymentsFetching } =
    paymentRegisterApi.useGetPaymentsQuery({ paymentRegistryId });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const showModal = () => {
    setIsModalOpened(true);
  };

  const handleOk = () => {
    setIsModalOpened(false);
  };

  const handleCancel = () => {
    setIsModalOpened(false);
  };

  const serviceNameFilterItems = useMemo(() => {
    const mappedData = payments?.map(
      (record) =>
        ({
          title: record.serviceName,
          key: record.serviceName,
        } as DataNode)
    );
    return mappedData?.filter(
      (value, index) =>
        mappedData.findIndex((obj) => obj.key === value.key) === index
    );
  }, [payments]);

  const columns: ColumnsType<IPaymentsMapped> = [
    {
      title: 'Лицевой счет',
      dataIndex: 'personalAccount',
      key: 'personalAccount',
      width: '100px',
      sorter: (a, b) =>
        stringSort(a.personalAccount, b.personalAccount, 'ascend'),
      ...getFilterSearchSettings<IPaymentsMapped>('personalAccount', {
        searchInput: searchInput as MutableRefObject<InputRef>,
        searchText,
        searchedColumn,
        setSearchedColumn,
        setSearchText,
      }),
    },
    {
      title: 'Название услуги',
      dataIndex: 'serviceName',
      key: 'serviceName',
      width: '200px',
      sorter: (a, b) => stringSort(a.serviceName, b.serviceName, 'ascend'),
      ...getFilterCheckboxSettings<IPaymentsMapped>('serviceName', {
        mode: 'menu',
        filters: serviceNameFilterItems,
      }),
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      width: '200px',
      sorter: (a, b) => stringSort(a.address, b.address, 'ascend'),
      ...getFilterSearchSettings<IPaymentsMapped>('address', {
        searchInput: searchInput as MutableRefObject<InputRef>,
        searchText,
        searchedColumn,
        setSearchedColumn,
        setSearchText,
      }),
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      width: '100px',
      sorter: (a, b) => numberSorter(a.amount, b.amount),
      render: (_, record) => toRubles(record.amount),
      ...getFilterSearchSettings<IPaymentsMapped>('amount', {
        searchInput: searchInput as MutableRefObject<InputRef>,
        searchText,
        searchedColumn,
        setSearchedColumn,
        setSearchText,
      }),
    },
  ];

  return (
    <>
      {button({ showModal })}
      {
        <ModalStyled
          style={{ top: '20px' }}
          // afterClose={() => {
          //   if (!orgData) {
          //     resetInputs()
          //   }
          //   setIsSaved(true);
          // }}
          // destroyOnClose={!orgData}
          // title={orgData ? 'Редактировать данные организации' : 'Добавить ораганизацию' }
          visible={isModalOpened}
          title={`Реестр оплат за ${registerDate}`}
          onOk={handleOk}
          width={'95%'}
          onCancel={handleCancel}
          footer={false}
        >
          <Table
            columns={columns}
            bordered
            size="small"
            pagination={{
              showSizeChanger: true,
            }}
            dataSource={payments}
            loading={ArePaymentsFetching}
            sticky
            scroll={{ x: 'max-content', y: 'calc(100vh - 440px)' }}
            css={`
              & .ant-pagination {
                margin-bottom: 0;
              }
            `}
          />
        </ModalStyled>
      }
    </>
  );
};

export default PaymentsModal;
