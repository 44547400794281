import { Button, Divider, Input, Space, Tree } from 'antd';
import { ColumnFilterItem, FilterConfirmProps } from 'antd/lib/table/interface';
import { DataNode } from 'antd/lib/tree';
import React, { FC, useEffect, useState } from 'react';

interface ICheckboxFilterDropdownProps {
  selectedKeys: React.Key[];
  setSelectedKeys: (selectedKey: React.Key[]) => void;
  dataIndex: string;
  type?: 'tree' | 'menu';
  clearFilters: (() => void) | undefined;
  confirm: (param?: FilterConfirmProps) => void;
  visible: boolean;
  filters: DataNode[] | undefined;
}

type getAllSelectedKeysArgsType = {
  key: React.Key;
  children?: getAllSelectedKeysArgsType[];
};

const getAllSelectedKeys = ({
  key,
  children = [],
}: getAllSelectedKeysArgsType): React.Key[] => {
  if (key === null) return [];
  const childrenMapped = children.reduce<React.Key[]>((result, element) => {
    return [
      ...result,
      ...getAllSelectedKeys({ key: element.key, children: element.children }),
    ];
  }, []);
  return [key, ...childrenMapped];
};

const CheckboxFilterDropdown: FC<ICheckboxFilterDropdownProps> = ({
  selectedKeys,
  setSelectedKeys,
  dataIndex,
  type = 'menu',
  clearFilters,
  confirm,
  visible,
  filters: _filters,
}) => {
  const [filters, setFilters] = useState(_filters);

  useEffect(() => {
    setFilters(_filters);
  }, [_filters]);

  useEffect(() => {
    if (!visible) {
      confirm();
    }
  }, [visible]);

  const onCheck = (checkedKeysValue: React.Key[] | {}) => {
    setSelectedKeys(checkedKeysValue as React.Key[]);
  };

  const handleFilter = (confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    // setFilterValue(selectedKeys[0]);
    // setFilteredColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    clearFilters();
    confirm();
    // setFilterValue('');
  };

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    // const result = getAllSelectedKeys({key: info.node.key, children: info.node.children});
    // const isAnyAlreadyIncluded = result.some((elem) => selectedKeys ? selectedKeys.includes(elem as string) : false);
    // if (isAnyAlreadyIncluded) {
    //   setCheckedKeys(checkedKeys.filter((elem) => !result.includes(elem)))
    // } else {
    //   setCheckedKeys([...checkedKeys, ...result]);
    // }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value !== '' &&
      e.target.value !== undefined &&
      _filters !== undefined
    ) {
      const filteredValues = (structuredClone(_filters) as DataNode[]).filter(
        (elem) =>
          elem.children
            ? elem.children.filter((child) =>
                child.title
                  ?.toString()
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              ).length !== 0
            : elem.title
                ?.toString()
                .toLowerCase()
                .includes(e.target.value.toLowerCase())
      );
      filteredValues.forEach((value) => {
        if (value.children) {
          value.children = value.children.filter((child) =>
            child.title
              ?.toString()
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          );
        }
      });
      setFilters(filteredValues);
    } else {
      setFilters(_filters);
    }
  };

  return (
    <div
      css={`
        padding: 8px;
      `}
    >
      <div
        css={`
          padding-bottom: 8px;
        `}
      >
        <Input
          placeholder="Найти значение"
          allowClear
          onChange={handleSearch}
        />
      </div>
      <div
        css={`
          border-bottom: 1px solid #d9d9d96d;
          border-radius: 8px !important;
          border-top: 1px solid #d9d9d96d;
          padding-top: 8px;
          max-height: 300px;
          width: calc(296px - 8px);
          overflow: auto;
          .ant-tree-switcher {
            display: ${type === 'menu' ? 'none' : null};
          }
          .ant-tree-checkbox {
            margin-right: ${type === 'menu' ? '4px' : null};
          }
        `}
      >
        <Tree
          treeData={filters}
          checkable
          selectable
          checkedKeys={selectedKeys}
          selectedKeys={selectedKeys}
          onCheck={onCheck}
          onSelect={onSelect}
          multiple
        />
      </div>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '8px',
        }}
      >
        <div>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, confirm);
            }}
            size="small"
            type="link"
            disabled={selectedKeys.length !== 0 ? false : true}
          >
            Сбросить
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => handleFilter(confirm)}
            size="small"
          >
            ОК
          </Button>
        </div>
      </Space>
    </div>
  );
};

export default CheckboxFilterDropdown;
