import { Dispatch, FC, SetStateAction } from 'react';
import ReactMarkdown from 'react-markdown';
import { Empty, Modal, Spin } from 'antd';
import { appSettingsApi } from '../../../store/api/appSettingsApi';
import { Wrap } from '../../users/components/Wrap';

interface IServicePrivacyPolicyProps {
  isOpened: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ServicePrivacyPolicy: FC<IServicePrivacyPolicyProps> = ({
  isOpened = false,
  setIsOpen,
}) => {
  const { data, isLoading } = appSettingsApi.useGetServicePrivacyPolicyQuery();

  const renderNode = data?.text ? (
    <ReactMarkdown>{data.text}</ReactMarkdown>
  ) : (
    <Empty description={false} />
  );
  const loader = (
    <Wrap>
      <Spin style={{ margin: '10px 0' }} tip={'Загрузка...'} />
    </Wrap>
  );

  return (
    <Modal
      visible={isOpened}
      onCancel={() => setIsOpen(false)}
      footer={null}
      title={'Политика конфиденциальности сервиса'}
      wrapClassName={'rules-modal-wrap'}
    >
      <>{isLoading ? loader : renderNode}</>
    </Modal>
  );
};

export default ServicePrivacyPolicy;
