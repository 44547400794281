import DebtRegisters from 'pages/debt-registers';
import PaymentRegisters from 'pages/payment-registers';
import Users from 'pages/users';
import Authorization from './pages/authorization';
import {
  AUTH,
  DEBT_REGISTERS,
  PAYMENT_REGISTERS,
  REPORTING,
  SETTINGS,
} from './utils/pageConst';
import Reporting from './pages/reporting';

interface IRoutes {
  name: string;
  path: string;
  component: React.ReactNode;
}

export const publicRoutes: readonly IRoutes[] = [
  {
    name: 'Авторизация',
    path: AUTH,
    component: <Authorization />,
  },
];

export const protectedRoutes: readonly IRoutes[] = [
  {
    name: 'Реестры оплат',
    path: PAYMENT_REGISTERS,
    component: <PaymentRegisters name="Реестры оплат" />,
  },
  {
    name: 'Реестры задолженностей',
    path: DEBT_REGISTERS,
    component: <DebtRegisters name="Реестры задолженностей" />,
  },
  {
    name: 'Отчеты',
    path: REPORTING,
    component: <Reporting name={'Отчеты'} />,
  },
  {
    name: 'Настройки',
    path: SETTINGS,
    component: <Users name="Настройки" />,
  },
];
