export const stringSort = (
  a: string,
  b: string,
  sortOrder: 'ascend' | 'descend'
): number => {
  if (a === undefined || b === undefined || a === null || b === null) return 0;
  if (sortOrder === 'ascend') {
    return a.localeCompare(b);
  } else if (sortOrder === 'descend') {
    return b.localeCompare(a);
  }
  return 0;
};
