import Paper from 'components/Paper';
import styled from 'styled-components';

export const Form = styled(Paper)`
  &&& {
    width: 400px;
    padding: 40px;
    & > * {
      margin-bottom: 20px;
    }
  }
`;
