import React, { FC, useState } from 'react';
import { IAuthContext } from '../interfaces/IAuthContext';
import { Role } from '../types';

export const AuthContext = React.createContext<IAuthContext>(null!);

interface IAuthProvider {
  children: React.ReactNode;
}

export const AuthProvider: FC<IAuthProvider> = ({ children }) => {
  const [roles, setRoles] = useState<Array<Role>>([]);

  const signin = (newRole: Array<Role>, callback: VoidFunction) => {
    setRoles(newRole);
    callback();
  };

  const signout = (callback: VoidFunction) => {
    setRoles([]);
    callback();
  };

  const value: IAuthContext = { roles, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
