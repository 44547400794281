import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { env } from 'process';
import { RootState, store } from 'store';
import { IAuth } from 'store/models/IAuth';
import { ILoginResponse } from 'store/models/ILoginResponse';
import authSlice from 'store/slices/authSlice';
import { API } from 'utils/endpointsConst';
import { getTokenFromCookies } from 'utils/helpers/cookies';
import { apiAddressConst } from '../../utils/apiAddressConst';

const baseQuery = fetchBaseQuery({
  baseUrl: apiAddressConst,
});

const baseQueryToken = fetchBaseQuery({
  baseUrl: apiAddressConst,
  prepareHeaders: (headers, api) => {
    // const token = (api.getState() as RootState).auth.token;
    const token = getTokenFromCookies();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn = async (args, api, extraOptions) => {
  let result = await baseQueryToken(args, api, extraOptions);

  if (result?.error?.status === 403) {
    console.log('sending refresh token');
    // send refresh token to get new access token
    const refreshResult = await baseQuery(API.auth.login, api, extraOptions);
    console.log(refreshResult);
    if (refreshResult?.data) {
      const user = (api.getState() as RootState).auth.token;
      // store the new token
      api.dispatch(
        authSlice.actions.setCredentials(refreshResult.data as ILoginResponse)
      );
      // retry the original query with new access token
      result = await baseQueryToken(args, api, extraOptions);
    } else {
      api.dispatch(authSlice.actions.deleteCredentials());
    }
  }

  return result;
};

export const baseProtectedApi = createApi({
  baseQuery: baseQueryToken,
  endpoints: (build) => ({}),
  reducerPath: 'baseProtectedApi',
  tagTypes: [
    'Organizations',
    'DebtRegisters',
    'PaymentRegisters',
    'PaymentRegistersFiles',
    'Users',
    'Events',
    'Reporting',
    'ReportStories',
  ],
});

export const basePublicApi = createApi({
  baseQuery,
  endpoints: (build) => ({}),
  reducerPath: 'basePublicApi',
});
